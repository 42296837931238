<div class="table-filter-section-title mb-2">Locations</div>
<div class="flex flex-wrap mt-1 mb-1">
  <div
    class="shop-select-item"
    [ngClass]="{
      'shop-select-active': allShopsSelected(),
      'shop-select-inactive': !allShopsSelected()
    }"
    (click)="selectAllShops()"
  >
    {{ allShopsSelected() ? 'Unselect' : 'Select' }} All
  </div>
  <div
    *ngFor="let item of groupsList"
    class="shop-select-item"
    [ngClass]="{
      'shop-select-active': item === selectedGroup,
      'shop-select-inactive': item !== selectedGroup
    }"
    (click)="selectedGroupChanged(item)"
  >
    {{ item }}
  </div>
</div>
<div class="flex flex-wrap max-w-[730px]">
  <lla-checkbox
    class="mt-1 w-full lg:w-1/2"
    *ngFor="let item of shopsList"
    [item]="item"
    controlName="locationIds"
  ></lla-checkbox>
</div>
