import { IBaseShop } from '@lla-platform/core/core-data-access'
import { Permission } from '@lla-platform/users/users-data-access'

export interface IAdminUserPermissionsResponse {
  userId: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  permissions: Permission[]
  locations: IBaseShop[]
  stateIds?: string[]
}

export interface IAdminUpdatePermissionsRequest {
  permissions: Permission[]
  locationIds: string[]
}
