import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { IRangeDateValue, ISelectItem } from '@lla-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'
import {
  BooleanStringOperators,
  CustomFilterTypes,
  DateOperators,
  NoFilterTypes,
  NumberOperators,
  StringOperators
} from '../advanced-table/advanced-table.constant'
import {
  AggregatorType,
  IGroupShopItem,
  ITableFilterItem,
  ITableFilterOption,
  PeriodType,
  TableOperator
} from '@lla-platform/core/core-data-access'
import { Sort } from '@angular/material/sort'
import { SortStateService } from '../../services/sort-state.services'
import { isArray } from 'lodash'
import { DateService } from '@lla-platform/core/core-util'
import { Store } from '@ngxs/store'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { ICategoryItem, ServiceCategoriesState } from 'service-categories-data-access'

@UntilDestroy()
@Component({
  selector: 'lla-advanced-table-filter',
  templateUrl: './advanced-table-filter.component.html',
  styleUrls: ['./advanced-table-filter.component.scss']
})
export class AdvancedTableFilterComponent implements OnInit {
  @Input() filters: ITableFilterOption
  @Input() sortState: Sort
  @Input() columnName: string
  @Input() sortColumnName?: string
  @Input() sortable = true
  @Output() filtersChanged = new EventEmitter()
  @Output() sortChanged = new EventEmitter()

  form: UntypedFormGroup
  itemsControl: UntypedFormArray
  operators: ISelectItem[] = []
  defaultOperator: TableOperator
  defaultAgregator: AggregatorType = 'Or'
  noFilterTypes = NoFilterTypes
  customFilterTypes = CustomFilterTypes
  periodType = PeriodType
  allEnumItems: ISelectItem[] = []
  selectedEnumItems: ISelectItem[] = []

  constructor(
    private fb: UntypedFormBuilder,
    public sortStateService: SortStateService,
    private dateService: DateService,
    private store: Store
  ) {}

  ngOnInit(): void {
    switch (this.filters.type) {
      case 'text':
        this.operators = StringOperators
        this.defaultOperator = 'Contains'
        break
      case 'number':
        this.operators = NumberOperators
        this.defaultOperator = 'Equals'
        break
      case 'date':
        this.operators = DateOperators
        this.defaultOperator = 'GreaterThan'
        break
      case 'enum':
        this.defaultOperator = 'Equals'
        break
      case 'enum-radio':
        this.defaultOperator = 'Equals'
        break
      case 'custom-date':
        this.defaultAgregator = 'And'
        break
      case 'booleanString':
        this.operators = BooleanStringOperators
        this.defaultOperator = 'Equals'
        break
      case 'location-list':
        this.defaultOperator = 'Equals'
        this.filters = {
          ...this.filters,
          enumItems: this.store.selectSnapshot<IGroupShopItem[]>(ShopsState.groupShops) ?? []
        }
        break
      case 'category-list':
        this.defaultOperator = 'Equals'
        this.filters = {
          ...this.filters,
          enumItems:
            this.store.selectSnapshot<ICategoryItem[]>(ServiceCategoriesState.categories)?.map((el) => ({
              label: el.name,
              value: el.id
            })) ?? []
        }
        break
    }

    this.allEnumItems = [...(this.filters.enumItems ?? [])]
    this.selectedEnumItems = [...this.allEnumItems]

    this.form = this.fb.group({
      aggregator: [this.filters?.aggregator ?? this.defaultAgregator],
      items: this.fb.array([])
    })

    this.itemsControl = this.form.get('items') as UntypedFormArray
    if (!this.filters?.items || this.filters.items.length < 1) {
      this.addNewRule()
    } else {
      for (const item of this.filters.items) {
        this.addNewRule(item)
      }
    }
  }

  addNewRule(item?: ITableFilterItem) {
    // Modify extra value before using
    let modifiedExtraValue: any
    if (item && item.operator === 'Period' && item.value === PeriodType.Custom && item.extraValue) {
      modifiedExtraValue = {
        from: item.extraValue.from ? new Date(item.extraValue.from) : undefined,
        to: item.extraValue.to ? new Date(item.extraValue.to) : undefined
      }
    }
    this.itemsControl.push(
      this.fb.group({
        operator: [item?.operator ?? this.defaultOperator],
        value: [item?.value, Validators.required],
        extraValue: [modifiedExtraValue ?? item?.extraValue]
      })
    )
  }

  removeRule(index: number) {
    this.itemsControl.removeAt(index)
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      const newFilters: ITableFilterOption = this.form.value
      this.filtersChanged.emit({ ...this.filters, ...newFilters })
    }

    if (
      !this.form.valid &&
      (this.filters.type === 'enum' ||
        this.filters.type === 'location-list' ||
        this.filters.type === 'category-list' ||
        this.filters.type === 'enum-radio')
    ) {
      this.clearFilters()
    }
  }

  clearFilters() {
    this.itemsControl.clear()
    this.addNewRule()
    this.filtersChanged.emit({ ...this.filters, items: [] })
  }

  addEnumToFormValue(formControl: AbstractControl, value: string) {
    if (!formControl.value || !isArray(formControl.value)) {
      formControl.setValue([value])
      return
    }

    const controlValue = [...formControl.value]
    const foundIndex = controlValue.findIndex((el) => el === value)
    if (foundIndex < 0) {
      controlValue.push(value)
    } else {
      controlValue.splice(foundIndex, 1)
    }
    formControl.setValue(controlValue)
  }

  customDateChanged(formControl: AbstractControl, value: IRangeDateValue) {
    if (!value.from && !value.to) {
      formControl.setValue(null)
      return
    }
    formControl.setValue({
      from: value.from ? this.dateService.convertToDateString(value.from) : null,
      to: value.to ? this.dateService.convertToDateString(value.to) : null
    })
  }

  enumSearchInputChanged(event: { target: { value: string } }) {
    const value = (event.target.value ?? '').trim().toLowerCase()
    if (!value || value === '') {
      this.selectedEnumItems = [...this.allEnumItems]
      return
    }
    this.selectedEnumItems = this.allEnumItems.filter((el) => el.label.toLowerCase().includes(value))
  }
}
