<div class="w-full flex justify-between py-1 px-2 border border-gray-300 rounded-1">
  <div #searchContainer class="flex-1 max-w-[calc(100%-16px)] flex items-center gap-1">
    @if (shouldGroup) {
    <div>
      <div
        (click)="
          appliedSearchesVisible = !appliedSearchesVisible;
          appliedSearchesVisible ? menuTrigger.openMenu() : undefined
        "
        class="flex items-center py-[3px] pl-2 pr-0.5 rounded-0.5 bg-primary cursor-pointer"
      >
        <div class="mr-2 text-sm leading-sm font-medium text-white">
          {{ searchItems.length }} searches applied
        </div>
        <div class="p-0.5 flex text-white cursor-pointer">
          <svg-icon [key]="appliedSearchesVisible ? 'arrow-up' : 'arrow-down'" fontSize="8px"></svg-icon>
        </div>
      </div>
      <div [matMenuTriggerFor]="searchItemsMenu" #menuTrigger="matMenuTrigger"></div>
    </div>
    } @else {
    <div
      class="max-w-full flex items-center py-[3px] pl-2 pr-0.5 rounded-0.5 border border-txt-secondary"
      *ngFor="let item of searchItems; let i = index"
    >
      <div class="mr-0.5 truncate text-sm leading-sm font-medium text-txt-secondary">{{ item }}</div>
      <div (click)="removePill(i)" class="p-0.5 flex text-txt-secondary cursor-pointer">
        <svg-icon key="close" fontSize="7px"></svg-icon>
      </div>
    </div>
    }

    <input
      autofocus
      (keyup.enter)="addNewPill()"
      (keydown.delete)="removeLastPill()"
      (keydown.backspace)="removeLastPill()"
      [(ngModel)]="searchTerm"
      type="text"
      class="flex-1 min-w-[100px] outline-none border-none text-base text-txt"
      placeholder="Search..."
    />
  </div>

  <div
    class="flex items-center h-4 text-txt-secondary"
    [ngClass]="{ 'cursor-pointer': searchItems.length > 0 }"
    (click)="removeAllPills()"
  >
    <svg-icon
      [key]="searchItems.length > 0 ? 'close' : 'search'"
      [fontSize]="searchItems.length > 0 ? '11px' : '15px'"
    ></svg-icon>
  </div>
</div>

<div #pillsContainer class="absolute left-0 top-0 w-fit flex items-center h-0 overflow-hidden">
  <div class="flex items-center py-[3px] pl-2 pr-0.5" *ngFor="let item of searchItems; let i = index">
    <div class="mr-0.5 text-sm leading-sm font-medium">{{ item }}</div>
    <div class="p-0.5 flex">
      <svg-icon key="close" fontSize="7px"></svg-icon>
    </div>
  </div>
</div>

<mat-menu
  #searchItemsMenu="matMenu"
  [hasBackdrop]="true"
  class="mg-search-pills-menu"
  (close)="appliedSearchesVisible = false"
>
  <ng-template matMenuContent>
    <div
      class="p-3 flex items-center flex-wrap gap-1"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    >
      <div
        class="max-w-full flex items-center py-[3px] pl-2 pr-0.5 rounded-0.5 border border-txt-secondary"
        *ngFor="let item of searchItems; let i = index"
      >
        <div class="mr-0.5 truncate text-sm leading-sm font-medium text-txt-secondary">{{ item }}</div>
        <div (click)="removePill(i)" class="p-0.5 flex text-txt-secondary cursor-pointer">
          <svg-icon key="close" fontSize="7px"></svg-icon>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>
