import { TagType } from '../enums/tag-type.enum'

export interface ITagAddRequest {
  contactLocationId: string
  type: TagType
}

export interface ITagDeleteRequest {
  contactLocationId: string
  tagId: string
}

export interface ITagItem {
  id: string
  contactId: string
  contactLocationId: string
  type: TagType
}

export interface ITagResponse {
  tags: ITagItem[]
}
