<button
  [attr.type]="type"
  [disabled]="disabled || loading"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-accent': variant === 'accent',
    'btn-secondary': variant === 'secondary',
    'btn-error': variant === 'error',
    'btn-error-invert': variant === 'error-invert',
    'btn-black': variant === 'black',
    'btn-black-invert': variant === 'black-invert',
    'btn-disabled': this.disabled,
    'btn-ghost': variant === 'ghost',
    'size-large': size === 'large',
    'size-medium': size === 'medium',
    'size-small': size === 'small',
    'size-smaller': size === 'smaller'
  }"
  class="btn-base {{ extraBtnClass }}"
  [disabled]="loading"
>
  <div
    class="w-full flex justify-center items-center"
    [ngClass]="{ 'flex-row-reverse': iconPosition === 'right' }"
  >
    <div *ngIf="loading" class="animate-spin rounded-full h-2 w-2 border-b-2 border-white"></div>
    <div *ngIf="!loading && icon">
      <div [ngSwitch]="iconType" class="flex items-center justify-center">
        <img *ngSwitchCase="'iconPath'" class="{{ iconClass }}" [src]="icon" />
        <svg-icon
          *ngSwitchCase="'svgIcon'"
          [key]="icon"
          [fontSize]="iconFontsize"
          class="{{ iconClass }}"
        ></svg-icon>
        <mat-icon *ngSwitchDefault class="{{ iconClass }}">{{ icon }}</mat-icon>
      </div>
    </div>
    <div
      class="lowercase first-letter:uppercase"
      [ngClass]="{
        'mr-1': (icon || loading) && iconPosition === 'right',
        'ml-1': (icon || loading) && iconPosition === 'left'
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</button>
