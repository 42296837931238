import { IArInvoicesListResponse } from '../../interfaces/ar-invoices-list.interface'
import { IArInvoicesResponse } from '../../interfaces/ar-invoices.interface'
import { IGpPackageListResponse } from '../../interfaces/gp-package-list.interface'
import { IGrossProfitListResponse } from '../../interfaces/gross-profit-list.interface'
import { IInvoiceGpPackageResponse } from '../../interfaces/invoice-gp-package.interface'
import { IInvoiceGrossProfitResponse } from '../../interfaces/invoice-gross-profit.interface'
import { IInvoiceDetailResponse } from '../../interfaces/invoice.interface'
import { IPackageUnperformedResponse } from '../../interfaces/package-unperformed.interface'

export interface ArInvoicesStateModel {
  listResponse: IArInvoicesListResponse
  grossProfitListResponse: IGrossProfitListResponse
  gpPackageListResponse: IGpPackageListResponse
  summaryResponse: IArInvoicesResponse
  invoiceResponse: IInvoiceDetailResponse
  grossProfit: IInvoiceGrossProfitResponse
  gpPackage: IInvoiceGpPackageResponse
  packageUnperformed: IPackageUnperformedResponse
}
