import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { environment } from '@lla-platform/core/core-util'
import { IUser, UserAccountState } from '@lla-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { map, Observable, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  constructor(private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.currentUser$.pipe(
      take(1),
      map((user) => {
        const canActivate = !!user

        if (!canActivate) {
          if (document.location.href.includes(environment.websiteUrl)) {
            this.router.navigate(['/login'], {
              queryParams: { returnUrl: state.url }
            })
          } else {
            document.location.href = `${environment.websiteUrl}/login?returnUrl=${state.url}`
          }
        }

        return canActivate
      })
    )
  }
}
