export interface IBaseShop {
  id: string
  name?: string
  nickname?: string
  noOfBays?: number
  stateId?: string
  state?: string
  region?: string
  stateName?: string
  division?: string
}

export interface IGroupShopItem {
  value: string
  label: string
  groups: string[]
}

export interface IGroupShopsResponse {
  locations: IGroupShopItem[]
}
