import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IArInvoiceRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface IArInvoiceItem extends IUrlFilters {
  locationId: string
  locationName: string
  noOfInvoices?: number
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  totalWithTax: number
  formattedTotalWithTax: string
}

export interface IArInvoiceTotal extends IUrlFilters {
  totalInvoices?: number
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  totalWithTax: number
  formattedTotalWithTax: string
}

export interface IArInvoicesResponse {
  timePeriod: ITimePeriod
  arReceivables: IArInvoiceItem[]
  totals: IArInvoiceTotal
}
