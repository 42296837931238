import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output } from '@angular/core'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import {
  BookmarksState,
  BookmarkTypeToLabel,
  DeleteBookmark,
  IBookmarkItem,
  IUser,
  Logout,
  UserAccountState
} from '@lla-platform/users/users-data-access'
import { finalize, Observable } from 'rxjs'
import { environment } from '@lla-platform/core/core-util'
import { IMenuRouteInfo } from '../../interfaces/menu.interface'
import { FiltersState } from '@lla-platform/core/core-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  @Input() mainMenuRoutes: IMenuRouteInfo[]
  @Input() profileMenuRoutes: IMenuRouteInfo[]
  @Output() showLargeMenuChanged = new EventEmitter<boolean>()

  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)
  bookmarks$: Observable<IBookmarkItem[]> = inject(Store).select(BookmarksState.bookmarks)

  deletingBookmarkId?: string
  showMenu = false
  showLargeMenu = false
  bookmarks: IMenuRouteInfo[] = []

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.showLargeMenu = !!this.store.selectSnapshot(FiltersState.showLargeMenu)
    this.showLargeMenuChanged.emit(this.showLargeMenu)

    this.addBookmarksMenuItems()

    this.bookmarks$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.addBookmarksMenuItems()
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth >= 976) {
      this.showMenu = false
    } else if (event.target.innerWidth < 976) {
      this.showLargeMenu = false
      this.showLargeMenuChanged.emit(false)
    }
  }

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      if (document.location.href.includes(environment.websiteUrl)) {
        this.router.navigateByUrl('/login')
      } else {
        document.location.href = `${environment.websiteUrl}/login`
      }
    })
  }

  addBookmarksMenuItems() {
    const bookmarkItems = this.store.selectSnapshot(BookmarksState.bookmarks)
    const listPagesPath = this.store.selectSnapshot(FiltersState.listPagesPath)
    this.bookmarks = bookmarkItems.map((el) => ({
      icon: 'redo',
      label: `${BookmarkTypeToLabel[el.type]} - ${el.name}`,
      type: 'bookmark',
      path: listPagesPath[el.type],
      queryParams: {
        filters: el.token
      },
      extraInfo: el.id
    }))
  }

  deleteBookmark(event: Event, id: string) {
    event.preventDefault()
    event.stopPropagation()

    if (this.deletingBookmarkId) {
      return
    }

    this.deletingBookmarkId = id
    this.store
      .dispatch(new DeleteBookmark(id))
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.deletingBookmarkId = undefined))
      )
      .subscribe()
  }

  hideCollapsableMenu() {
    setTimeout(() => {
      this.showMenu = false
    })
  }
}
