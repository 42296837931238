import { IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IWorkOrdersSummaryRequest {
  locationIds?: string[]
}

export interface IWorkOrdersSummaryItem extends IUrlFilters {
  locationId?: string
  locationName?: string
  leesThan7Days: number
  leesThan14Days: number
  leesThan30Days: number
  greaterThan30Days: number
  grandTotals: number
}

export interface IWorkOrdersSummaryResponse {
  workOrders: IWorkOrdersSummaryItem[]
  leesThan7Days: number
  leesThan14Days: number
  leesThan30Days: number
  greaterThan30Days: number
  grandTotals: number
}

export interface IWorkOrdersExtendedResponse {
  workOrders: IWorkOrdersSummaryItem[]
  grandTotal: IWorkOrdersSummaryItem
}
