<div class="modal-container relative p-5.5 pb-5">
  <!-- Close Btn -->
  <div class="absolute cursor-pointer top-1 right-3">
    <svg-icon color="#374151" key="close" fontSize="10px" (click)="dialogRef.close()"></svg-icon>
  </div>

  <!-- Title -->
  <div class="mt-1 text-2xl leading-2.5xl text-black">Add Bookmark</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col mt-4">
    <lla-input controlName="name" label="Bookmark title"></lla-input>

    <div class="flex items-center justify-center mt-4">
      <lla-button
        class="w-1/2"
        type="submit"
        variant="black"
        size="medium"
        [loading]="isLoading"
        [disabled]="form.invalid"
      >
        Add
      </lla-button>
      <lla-button
        class="ml-3 w-1/2"
        size="medium"
        variant="black-invert"
        type="button"
        (click)="dialogRef.close()"
      >
        Cancel
      </lla-button>
    </div>
  </form>
</div>
