import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { MatFormFieldAppearance } from '@angular/material/form-field'
import { UntilDestroy } from '@ngneat/until-destroy'
import { PeriodType, PeriodTypeButtonsInfo, TodayString } from '@lla-platform/core/core-data-access'
import { IInputErrorMessages, IRangeDateValue } from '@lla-platform/core/core-ui'
import { MatDateRangePicker } from '@angular/material/datepicker'
import { IRangePickerEvent } from '../../interfaces/input-range-date.interface'
import moment, { isMoment } from 'moment'

@UntilDestroy()
@Component({
  selector: 'lla-input-range-date-filter',
  templateUrl: './input-range-date-filter.component.html',
  styleUrls: [
    '../../../../../core-ui/src/lib/components/input/input.component.scss',
    './input-range-date-filter.component.scss'
  ]
})
export class InputRangeDateFilterComponent implements OnInit, OnChanges {
  @ViewChild('picker') picker: MatDateRangePicker<Date>

  @Input() label: string
  @Input() prefix: string
  @Input() size: 'small' | 'default' = 'default'
  @Input() appearance: MatFormFieldAppearance = 'outline'
  @Input() debounceDuration = 0
  @Input() errorMessages: IInputErrorMessages[] = []
  @Input() customErrorMatcher: ErrorStateMatcher
  @Input() customError: {
    show: boolean
    message: string
  }
  @Input() autoFocus = false
  @Input() loading = false
  @Input() disabled = false
  @Input() icon: string
  @Input() iconColor = '#808080'
  @Input() iconClass = ''
  @Input() iconSize = '16px'
  @Input() maxDate? = TodayString
  @Input() minDate?: Date
  @Input() initialValue?: IRangeDateValue
  @Input() selectedPeriodType: PeriodType

  @Output() valueChanged = new EventEmitter<IRangePickerEvent>()
  @Output() iconClicked = new EventEmitter()

  rangeForm: UntypedFormGroup
  allErrorMessages: IInputErrorMessages[] = []
  periodTypeButtonsInfo = PeriodTypeButtonsInfo.filter((el) => el.value !== PeriodType.Custom)
  periodType = PeriodType

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.selectedPeriodType !== PeriodType.Custom) {
      this.initialValue = this.setDateByPeriod(this.selectedPeriodType)
    }
    this.rangeForm = this.fb.group({
      from: [this.initialValue?.from ? moment(this.initialValue?.from).utc() : undefined],
      to: [this.initialValue?.to ? moment(this.initialValue?.to).utc() : undefined]
    })
  }

  ngOnChanges() {
    if (this.loading || this.disabled) {
      this.rangeForm?.disable()
    } else {
      this.rangeForm?.enable()
    }
  }

  handleErrorMessage() {
    if (this.customError?.show && this.customError?.message) {
      return this.customError.message
    }
    const allErrors: ValidationErrors = this.rangeForm?.errors ?? {}
    return this.allErrorMessages.find((el) => allErrors[el.type])?.message ?? ''
  }

  isFormInvalid() {
    return (
      (this.customError?.show || this.rangeForm?.invalid) &&
      (this.rangeForm?.dirty || this.rangeForm?.touched)
    )
  }

  applyPeriodDate(period: PeriodType) {
    this.selectedPeriodType = period
    const { from, to } = this.setDateByPeriod(period)
    this.picker.select(moment(from) as any)
    this.picker.select(moment(to) as any)
    this.fireValueChangedEvent()
  }

  fireValueChangedEvent() {
    const from = this.rangeForm.get('from')?.value
    let to = this.rangeForm.get('to')?.value

    if (!to) {
      to = from
      this.rangeForm.get('to')?.setValue(to)
    }

    this.valueChanged.emit({
      rangeDate: {
        from: isMoment(from) ? from.utcOffset(0, true).format() : from,
        to: isMoment(to) ? to.utcOffset(0, true).format() : to
      },
      periodType: this.selectedPeriodType
    })
    this.picker.close()
  }

  setDateByPeriod(period: PeriodType): Required<IRangeDateValue> {
    return this.periodTypeButtonsInfo.find((el) => el.value === period)?.extraInfo
  }
}
