import { Injectable } from '@angular/core'
import { Sort } from '@angular/material/sort'

@Injectable({
  providedIn: 'root'
})
export class SortStateService {
  update(newState: Sort, prevState?: Sort, detectDirection?: boolean): Sort {
    if (detectDirection) {
      if (prevState?.active !== newState?.active) {
        newState.direction = 'asc'
      } else {
        switch (prevState?.direction) {
          case 'asc':
            newState.direction = 'desc'
            break

          case 'desc':
            newState.direction = ''
            break

          default:
            newState.direction = 'asc'
            break
        }
      }
    }
    if (
      !prevState?.active ||
      prevState.active !== newState.active ||
      prevState.direction !== newState.direction
    ) {
      return newState
    }

    return {
      active: newState.active,
      direction: ''
    }
  }
}
