export interface ICustomerInvoiceItem {
  invoiceId: string
  invoiceNumber: number
  invoiceDate: Date
  grandTotal: string
}

export interface ICustomerInvoicesResponse {
  hasMore: boolean
  items: ICustomerInvoiceItem[]
}
