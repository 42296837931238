import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsModule } from '@ngxs/store'
import { commonIcons, socialIcons } from '@lla-platform/icons'
import { CoreUiModule } from '@lla-platform/core/core-ui'
import { ToastrModule } from 'ngx-toastr'
import { environment, GlobalErrorHandler } from '@lla-platform/core/core-util'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BookmarksState, UserAccountState } from '@lla-platform/users/users-data-access'
import { AppRoutingModule } from './app-routing.module'
import { AuthLayoutModule } from './layout/auth-layout/auth-layout.module'
import { MainLayoutModule } from './layout/main-layout/main-layout.module'
import { ErrorInterceptor, CoreFeatureModule } from '@lla-platform/core/core-feature'
import { MatDialogModule } from '@angular/material/dialog'
import {
  ArInvoicesState,
  ArPaymentsState,
  ReceivableState
} from '@lla-platform/receivable/receivable-data-access'
import { FiltersState } from '@lla-platform/core/core-data-access'
import { CustomersState } from '@lla-platform/customers/customers-data-access'
import { WeeklyCarCountsState } from '@lla-platform/car-counts/car-counts-data-access'
import { WeeklyTargetState } from '@lla-platform/targets/targets-data-access'
import { ReviewsState } from '@lla-platform/reviews/reviews-data-access'
import { TimeagoModule } from 'ngx-timeago'
import { ReportsState } from '@lla-platform/reports/reports-data-access'
import { PaymentsState } from '@lla-platform/payments/payments-data-access'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { InventoriesState } from '@lla-platform/inventories/inventories-data-access'
import { AdminState } from '@lla-platform/admin/admin-data-access'
import { PromotionsState } from '@lla-platform/promotions/promotions-data-access'
import { TiresState } from '@lla-platform/tires/tires-data-access'
import { TrackerState } from '@lla-platform/tracker/tracker-data-access'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatTableModule } from '@angular/material/table'
import { ServiceCategoriesState } from 'service-categories-data-access'
import { RouteReuseStrategy } from '@angular/router'
import { CustomReuseStrategy } from './core/route-strategy/custom-reuse.strategy'
import { SurveyState } from 'survey-data-access'
import { PublicLayoutModule } from './layout/public-layout/public-layout.module'
import { PageState } from 'page-data-access'
import { WorkOrdersState } from 'work-orders-data-access'

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    NgxsModule.forRoot(
      [
        UserAccountState,
        ReceivableState,
        FiltersState,
        CustomersState,
        WeeklyCarCountsState,
        ArInvoicesState,
        BookmarksState,
        WeeklyTargetState,
        ArPaymentsState,
        ReviewsState,
        ReportsState,
        PaymentsState,
        ShopsState,
        InventoriesState,
        AdminState,
        PromotionsState,
        TiresState,
        ServiceCategoriesState,
        TrackerState,
        SurveyState,
        PageState,
        WorkOrdersState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    SvgIconsModule.forRoot({
      icons: [...commonIcons, ...socialIcons]
    }),
    CoreUiModule,
    MainLayoutModule,
    PublicLayoutModule,
    AuthLayoutModule,
    CoreFeatureModule,
    TimeagoModule.forRoot(),
    MatProgressSpinnerModule,
    MatTableModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
