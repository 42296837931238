import { StarRating } from '../enums/star-rating.enum'

export interface IReviewer {
  profilePhotoUrl?: string
  displayName?: string
  chatGptMessage?: string
}

export interface IReviewReply {
  comment: string
  updateTime: Date
}

export interface IReviewItem {
  reviewId: string
  comment: string
  reviewer: IReviewer
  starRating: StarRating
  createTime: Date
  updateTime: Date
  reviewReply: IReviewReply
}

export interface IReviewShopInfo {
  locationName: string
  locationId: string
}

export interface IShopReview {
  locationName: string
  locationId: string
  reviews: IReviewItem[]
}

export interface IReviewsResponse {
  redirectUrl?: string
  locations: IShopReview[]
}
