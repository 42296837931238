import {
  ContactType,
  IAdvancedTable,
  IAdvancedTableBaseInfo,
  IPaginationBaseRequest,
  IPaginationFilteredResponse,
  ITimePeriodRequest
} from '@lla-platform/core/core-data-access'
import { WorkCategoryType } from '../enums/work-category-type.enum'
import { SatisfactionType } from '../enums/satisfaction-type.enum'
import { CommunicationStatus } from '../enums/communication-status.enum'
import { CommunicationSource } from '../enums/communication-source.enum'
import { TagType } from '../enums/tag-type.enum'

export interface ICommunicationsListInfo {
  tableInfo?: IAdvancedTableBaseInfo
  filters?: ICommunicationsListRequest
}

export interface ICommunicationsListRequest extends IPaginationBaseRequest {
  period?: ITimePeriodRequest
  callStatus?: CommunicationStatus
  date?: Date
  name?: string
  source?: CommunicationSource
  corporateContact?: boolean
  workCategories?: WorkCategoryType[]
  satisfaction?: SatisfactionType[]
  locationIds?: string[]
  agentIds?: string[]
  contactType?: ContactType[]
  tags?: TagType[]
}

export interface ICommunicationsListItem extends IAdvancedTable {
  id: string
  contactId: string
  locationId: string
  invoiceId: string
  agentId: string
  status: CommunicationStatus
  date: Date
  agentName: string
  contactName: string
  companyName: string
  locationName: string
  workCategories: WorkCategoryType[]
  satisfaction: SatisfactionType
  source: CommunicationSource
  tags: TagType[]
  invoiceDate: Date
  phone: string
  contactType: ContactType
  customerComments: string
  customerServiceNotes: string
  storeManagerNotes: string
  regionalManagerNotes: string
  otherComments: string
}

export interface ICommunicationsListResponse extends IPaginationFilteredResponse {
  communications: ICommunicationsListItem[]
}
