import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'lla-search-pills',
  templateUrl: './search-pills.component.html',
  styleUrls: ['./search-pills.component.scss']
})
export class SearchPillsComponent implements OnChanges {
  @ViewChild('searchContainer', { static: false }) searchContainer: ElementRef
  @ViewChild('pillsContainer', { static: false }) pillsContainer: ElementRef

  @Input() searchItems: string[] = []
  @Output() searchItemsChanged = new EventEmitter<string[]>()

  searchTerm = ''
  shouldGroup = false
  appliedSearchesVisible = false

  ngOnChanges(): void {
    this.checkShouldGroup()
  }

  addNewPill() {
    const value = (this.searchTerm ?? '').trim()
    if (value === '') {
      return
    }
    this.searchItems.push(value)
    this.searchTerm = ''
    this.onSearchItemsChange()
  }

  removePill(index: number) {
    this.searchItems.splice(index, 1)
    this.onSearchItemsChange()
  }

  removeAllPills() {
    this.searchItems = []
    this.onSearchItemsChange()
  }

  removeLastPill() {
    if (this.searchTerm && this.searchTerm !== '') {
      return
    }
    if (!this.searchItems || this.searchItems.length < 1) {
      return
    }
    this.searchItems.splice(-1, 1)
    this.onSearchItemsChange()
  }

  onSearchItemsChange() {
    this.checkShouldGroup()
    this.searchItemsChanged.emit(this.searchItems)
  }

  checkShouldGroup() {
    setTimeout(() => {
      const searchContainer = this.searchContainer?.nativeElement as HTMLElement
      const pillsContainer = this.pillsContainer?.nativeElement as HTMLElement
      if (searchContainer && pillsContainer) {
        const pillsContainerWidth = pillsContainer.clientWidth
        const maxPillContainerWidth = searchContainer.clientWidth - 130
        this.shouldGroup = pillsContainerWidth > maxPillContainerWidth
      }
    })
  }
}
