import { SurveyQuestionType } from '../enums/survey-question-type.enum'

export interface ISurveyQuestion {
  questionId: string
  type: SurveyQuestionType
  text: string
  options?: string[]
  mandatory: boolean
  allowComment: boolean
  commentDescription: string
}

export interface ISurveyContent {
  questions: ISurveyQuestion[]
}

export interface ISurveyResponse {
  id: string
  title: string
  description: string
  content: string
  locationName?: string
}

export interface ISurveyRequestItem {
  questionId: string
  response: any
}

export interface ISurveyRequest {
  locationId?: string
  responses: ISurveyRequestItem[]
}
