import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Sort } from '@angular/material/sort'
import {
  IAdvancedTable,
  ITableFilter,
  ITableFilterOption,
  IAdvancedTableColumn,
  IAdvancedTableInfo,
  IColumnsVisibility,
  ISubtitleInfo,
  ITableFilterItem,
  FiltersState,
  SetFilters,
  IAdvancedTableClickEvent,
  PeriodType,
  PeriodTypeToLabel,
  IGroupShopItem
} from '@lla-platform/core/core-data-access'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatMenuTrigger } from '@angular/material/menu'
import { TableOperatorToSentence, TotalRowUniqueId } from './advanced-table.constant'
import { cloneDeep } from 'lodash'
import { environment } from '@lla-platform/core/core-util'
import { Store } from '@ngxs/store'
import { BookmarkType } from '@lla-platform/users/users-data-access'
import { MatDialog } from '@angular/material/dialog'
import { AddBookmarkDialogComponent } from '../add-bookmark-dialog/add-bookmark-dialog.component'
import { DatePipe } from '@angular/common'
import { ToastrService } from 'ngx-toastr'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { ICategoryItem, ServiceCategoriesState } from 'service-categories-data-access'

@UntilDestroy()
@Component({
  selector: 'lla-advanced-table',
  templateUrl: './advanced-table.component.html',
  styleUrls: ['./advanced-table.component.scss'],
  providers: [DatePipe]
})
export class AdvancedTableComponent implements OnInit, OnChanges {
  @Input() sortState: Sort
  @Input() displayedColumns: IAdvancedTableColumn[] = []
  @Input() totalResults = 0
  @Input() dataSource: IAdvancedTable[] = []
  @Input() tableFilters: ITableFilter
  @Input() isLoading = false
  @Input() isLoadingExportFile = false
  @Input() page = 1
  @Input() title = ''
  @Input() subtitleInfo: ISubtitleInfo
  @Input() bookmarkType: BookmarkType
  @Input() advancedFilter = true
  @Output() tableInfoChanged = new EventEmitter<IAdvancedTableInfo>()
  @Output() columnsVisibilityChanged = new EventEmitter()
  @Output() tableRowClicked = new EventEmitter<IAdvancedTableClickEvent<any>>()
  @Output() downloadExportFileClicked = new EventEmitter()
  @Output() filterButtonClicked = new EventEmitter()

  @ViewChild('filterMenuTrigger') filterMenuTrigger: MatMenuTrigger

  filterdDisplayedColumns: IAdvancedTableColumn[] = []
  filterdDisplayedColumnKeys: string[] = []
  showHideColumnsForm: UntypedFormGroup
  showAppliedFilters = false

  pageSize = environment.pageSize
  totalPages = 0
  totalRowUniqueId = TotalRowUniqueId
  periodTypes = PeriodType
  periodTypeToLabel = PeriodTypeToLabel

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private dialogService: MatDialog,
    private datePipe: DatePipe,
    public toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.showAppliedFilters = !!this.store.selectSnapshot(FiltersState.showAppliedTableFilters)
    const formFields = Object.fromEntries(
      this.displayedColumns.filter((el) => el.visualType !== 'none').map((el) => [el.key, !el.hide])
    )
    this.showHideColumnsForm = this.fb.group(formFields)
    this.setFilterdDisplayedColumns(formFields)
    this.showHideColumnsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      this.columnsVisibilityChanged.emit(res)
      this.setFilterdDisplayedColumns(res)
    })
  }

  ngOnChanges(): void {
    this.totalPages = Math.ceil(this.totalResults / this.pageSize)
  }

  infoChanged(resetPage = false) {
    if (resetPage) {
      this.page = 1
    }
    this.tableInfoChanged.emit({
      filters: this.tableFilters,
      page: this.page,
      sortState: this.sortState
    })
  }

  tableClicked(index: number, element: IAdvancedTable, columnName: string) {
    this.tableRowClicked.emit({ element, columnName })
    if (!element.items || element.items.length < 1) {
      return
    }
    if (this.dataSource.find((el) => el.parentId === element.rowId)) {
      this.dataSource = this.dataSource.filter((el) => this.rowShouldHide(element.rowId, el))
    } else {
      this.dataSource = [
        ...this.dataSource.slice(0, index + 1),
        ...(element.items?.map((el) => ({ ...el, parentId: element.rowId })) ?? []),
        ...this.dataSource.slice(index + 1)
      ]
    }
  }

  rowShouldHide(parentId: string, element?: IAdvancedTable): boolean {
    if (!element?.parentId || element.parentId === '') {
      return true
    }
    if (element.parentId === parentId) {
      return false
    }
    return this.rowShouldHide(
      parentId,
      this.dataSource.find((el) => el.rowId === element.parentId)
    )
  }

  toggleShowHideCheckBox(item: string) {
    document.getElementById(`showHide${item}`)?.click()
  }

  setFilters(filter: ITableFilterOption, item: string) {
    this.tableFilters = cloneDeep(this.tableFilters)
    this.tableFilters[item] = { ...filter }
    this.filterMenuTrigger?.closeMenu()
    this.infoChanged(true)
  }

  removeFilterItem(item: string, itemIndex: number) {
    this.tableFilters = cloneDeep(this.tableFilters)
    const selectedTableFilter = this.tableFilters[item]
    this.tableFilters[item] = {
      ...selectedTableFilter,
      items: selectedTableFilter?.items?.filter((el, index) => index !== itemIndex)
    }
    this.infoChanged(true)
  }

  setFilterdDisplayedColumns(values: IColumnsVisibility) {
    this.filterdDisplayedColumns = this.displayedColumns.filter(
      (el) => el.visualType !== 'none' && values[el.key] === true
    )
    this.filterdDisplayedColumnKeys = this.filterdDisplayedColumns.map((el) => el.key)
  }

  numberOfAppliedFilters() {
    return Object.keys(this.tableFilters)
      ?.map((el) => {
        const items = this.tableFilters[el]?.items
        return items ? items.length : 0
      })
      .reduce((sum, el) => sum + el, 0)
  }

  generateFilterText(key: string, item: ITableFilterItem) {
    let filterText = `${this.displayedColumns.find((el) => el.key === key)?.label} `
    if (item.operator !== 'Period' || item.value !== PeriodType.Custom || !item.extraValue) {
      return (filterText += TableOperatorToSentence[item.operator])
    }

    // Custom date period
    return (filterText +=
      (item.extraValue?.from
        ? `from ${this.datePipe.transform(new Date(item.extraValue?.from), 'MMM d, y', 'utc')} `
        : '') +
      (item.extraValue?.to
        ? `to ${this.datePipe.transform(new Date(item.extraValue?.to), 'MMM d, y', 'utc')}`
        : ''))
  }

  headerHasFilters(key: string) {
    const filter = this.tableFilters[key]
    return filter?.items && filter.items.length > 0
  }

  headerIsSorted(key: string) {
    return (
      this.sortState &&
      this.sortState.direction &&
      (this.sortState.direction as string) !== '' &&
      this.sortState.active === key
    )
  }

  showAppliedFiltersChanged() {
    if (this.advancedFilter) {
      this.showAppliedFilters = !this.showAppliedFilters
      this.store.dispatch(
        new SetFilters({
          showAppliedTableFilters: this.showAppliedFilters
        })
      )
      return
    }

    this.filterButtonClicked.emit()
  }

  showAddBookmarkModal() {
    this.dialogService.open(AddBookmarkDialogComponent, {
      autoFocus: false,
      panelClass: 'normal-modal',
      data: {
        bookmarkType: this.bookmarkType
      }
    })
  }

  getShopNamesWithId(locationId: string[]) {
    const groupShops = this.store.selectSnapshot<IGroupShopItem[]>(ShopsState.groupShops) ?? []
    return locationId.map((id) => groupShops.find((s) => s.value === id)?.label).join(', ')
  }

  getCategoryNamesWithId(categoriesId: string[]) {
    const allCategories =
      this.store.selectSnapshot<ICategoryItem[]>(ServiceCategoriesState.categories) ?? []
    return categoriesId.map((id) => allCategories.find((c) => c.id === id)?.name).join(', ')
  }

  enumListToLabels(values: string[], enumItems?: ISelectItem[]) {
    return values
      .map((value) => {
        const label = enumItems?.find((el) => el.value === value)?.label
        return label ?? value
      })
      .join(', ')
  }

  enumItemToLabel(key: string, value: string) {
    const enumItems = this.tableFilters[key]?.enumItems
    const label = enumItems?.find((el) => el.value === value)?.label
    return label ?? value
  }
}
