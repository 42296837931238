import { Component, Input, OnInit } from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { IGroupShopItem } from '@lla-platform/core/core-data-access'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-shops-selector',
  templateUrl: './shops-selector.component.html',
  styleUrls: ['./shops-selector.component.scss']
})
export class ShopsSelectorComponent implements OnInit {
  @Input() controlName: string
  @Input() selectedGroup?: string

  formControl: UntypedFormControl
  groupsList: string[] = []
  shopsList: IGroupShopItem[] = []

  constructor(private store: Store, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
    this.shopsList = this.store.selectSnapshot<IGroupShopItem[]>(ShopsState.groupShops)
    this.groupsList = this.store.selectSnapshot<string[]>(ShopsState.groups)
  }

  selectedGroupChanged(item: string) {
    this.selectedGroup = item
    const selectedShopsId = this.shopsList
      .filter((el) => el.groups?.includes(item))
      .map((el) => el.value)
    this.formControl?.setValue(selectedShopsId)
  }

  allShopsSelected() {
    const locationIds = this.formControl?.value
    return locationIds && locationIds.length === this.shopsList.length
  }

  selectAllShops() {
    this.selectedGroup = undefined
    const locationIds = this.formControl?.value
    if (locationIds && locationIds.length === this.shopsList.length) {
      this.formControl?.reset()
      return
    }
    this.formControl?.setValue(this.shopsList.map((el) => el.value))
  }
}
