import { IBaseUser } from '@lla-platform/users/users-data-access'
import { IPromotionBase } from './promotions.interface'

export interface IPromotionUsersItem {
  locationId: string
  locationName?: string
  users: IBaseUser[]
  promotions: IPromotionBase[]
}

export interface IPromotionUsersResponse {
  items: IPromotionUsersItem[]
}

export interface IPromotionEmailRequest {
  items: IPromotionUsersItem[]
}
