import { IUserPreferencesResponse } from '../../interfaces/user-preferences.interface'
import { IUser } from '../../interfaces/user.interface'

export interface UserAccountStateModel {
  user: IUser
  isAuthenticated: boolean
  shouldReload: boolean
  userPreferences: IUserPreferencesResponse
  returnUrl: string
}
