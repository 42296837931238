<div class="flex items-center">
  <input
    id="{{ controlName }}-{{ item.value }}-radio"
    type="radio"
    [value]="item.value"
    class="hidden"
    [formControl]="formControl"
    name="{{ controlName }}"
  />
  <label for="{{ controlName }}-{{ item.value }}-radio" class="flex items-center group">
    <ng-container *ngIf="formControl?.value !== item.value">
      <div class="flex group-hover:hidden">
        <svg-icon key="circle-empty" color="#C3C3C3"></svg-icon>
      </div>
      <div class="hidden group-hover:flex">
        <svg-icon key="circle-borderd" color="#006EA8"></svg-icon>
      </div>
    </ng-container>
    <img *ngIf="formControl?.value === item.value" src="assets/images/circle-fill.svg" />
    <div class="ml-1 text-lg leading-xl text-txt-secondary font-medium">{{ item.label }}</div>
  </label>
</div>
