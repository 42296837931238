import { BookmarkType } from '../enums/bookmark-type'

export interface IAddBookmarkRequest {
  token: string
  name: string
  type: BookmarkType
}

export interface IBookmarkItem {
  id: string
  name: string
  token: string
  type: BookmarkType
}

export interface IBookmarkResponse {
  items: IBookmarkItem[]
}
