import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { commonIcons } from '@lla-platform/icons'
import { InputComponent } from './components/input/input.component'
import { ButtonComponent } from './components/button/button.component'
import { NgxMaskModule } from 'ngx-mask'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { GooglePlaceInputComponent } from './components/google-place-input/google-place-input.component'
import { MatIconModule } from '@angular/material/icon'
import { ButtonGroupComponent } from './components/button-group/button-group.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { CheckboxComponent } from './components/checkbox/checkbox.component'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { PaginationComponent } from './components/pagination/pagination.component'
import { TabsComponent } from './components/tabs/tabs.component'
import { FooterComponent } from './components/footer/footer.component'
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component'
import { RadioComponent } from './components/radio/radio.component'
import { SliderComponent } from './components/slider/slider.component'
import { InputRangeDateComponent } from './components/input-range-date/input-range-date.component'
import { NoReportBannerComponent } from './components/no-report-banner/no-report-banner.component'
import { AutofocusDirective } from './directives/auto-focus.directive'
import { FilterButtonComponent } from './components/filter-button/filter-button.component'
import { GoogleMapsModule } from '@angular/google-maps'
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { CheckboxElementComponent } from './components/checkbox-element/checkbox-element.component'
import { SafeHtmlPipe } from './pipes/safe-html.pipe'
import { SafeUrlPipe } from './pipes/safe-url.pipe'
import { SearchPillsComponent } from './components/search-pills/search-pills.component'
import { MatMenuModule } from '@angular/material/menu'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconsModule.forRoot({ icons: commonIcons }),
    NgxMaskModule.forRoot(),
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CurrencyMaskModule,
    NgxPaginationModule,
    GoogleMapsModule,
    MatMomentDateModule,
    MatMenuModule
  ],
  declarations: [
    AutofocusDirective,
    SafeUrlPipe,
    SafeHtmlPipe,
    InputComponent,
    ButtonComponent,
    GooglePlaceInputComponent,
    ButtonGroupComponent,
    CheckboxComponent,
    PaginationComponent,
    TabsComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    RadioComponent,
    SliderComponent,
    InputRangeDateComponent,
    NoReportBannerComponent,
    FilterButtonComponent,
    CheckboxElementComponent,
    SearchPillsComponent
  ],
  exports: [
    SafeUrlPipe,
    SafeHtmlPipe,
    InputComponent,
    ButtonComponent,
    GooglePlaceInputComponent,
    ButtonGroupComponent,
    CheckboxComponent,
    PaginationComponent,
    TabsComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    RadioComponent,
    SliderComponent,
    InputRangeDateComponent,
    NoReportBannerComponent,
    FilterButtonComponent,
    CheckboxElementComponent,
    SearchPillsComponent
  ],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }]
})
export class CoreUiModule {}
