export interface ISurveySummaryItem {
  id: string
  title: string
  description: string
  reponseCount: number
}

export interface ISurveySummaryResponse {
  items: ISurveySummaryItem[]
}
