import {
  IArInvoiceRequest,
  IGpPackageListRequest,
  IInvoiceGrossProfitRequest,
  IPackageUnperformedRequest,
  IReceivablesSummaryByShopRequest
} from '@lla-platform/receivable/receivable-data-access'
import { IAdvancedTableInfo, IColumnsVisibility } from '../../interfaces/advanced-table.interface'
import { ITimePeriodRequest } from '../../interfaces/time-period.interface'
import {
  ICommunicationTypeRequest,
  ICommunicationsListInfo,
  ICustomerRequest,
  ICustomersListInfo,
  IDeferredTypeRequest
} from '@lla-platform/customers/customers-data-access'
import { Sort } from '@angular/material/sort'
import { ITiresRequest } from '@lla-platform/tires/tires-data-access'
import { IWeeklyCarCountsRequest } from '@lla-platform/car-counts/car-counts-data-access'
import { IWorkOrdersSummaryRequest } from 'work-orders-data-access'

export interface FiltersStateModel {
  listPagesPath: { [key: string]: string }
  receivableTableFilters: Partial<IAdvancedTableInfo>
  receivableColumnsVisibility: IColumnsVisibility
  receivableListReturnUrl: string
  shopReceivableSummaryFilter: IReceivablesSummaryByShopRequest
  arSummaryFilter: IArInvoiceRequest
  invoicesListTableFilters: Partial<IAdvancedTableInfo>
  invoicesListColumnsVisibility: IColumnsVisibility
  invoicesListReturnUrl: string
  grossListTableFilters: Partial<IAdvancedTableInfo>
  grossListColumnsVisibility: IColumnsVisibility
  grossListReturnUrl: string
  gpPackageListTableFilters: Partial<IAdvancedTableInfo>
  gpPackageListColumnsVisibility: IColumnsVisibility
  gpPackageListReturnUrl: string
  accountReceivableFilter: IReceivablesSummaryByShopRequest
  showLargeMenu: boolean
  showAppliedTableFilters: boolean
  customersSummaryFilter: ICustomerRequest
  deferredTypesSummaryFilter: IDeferredTypeRequest
  deferredTypesSortState: Sort
  grossProfitSummaryFilter: IInvoiceGrossProfitRequest
  grossProfitSortState: Sort
  gpPackageByLocationSummaryFilter: IGpPackageListRequest
  gpPackageByLocationSortState: Sort
  gpPackageByCategorySummaryFilter: IGpPackageListRequest
  gpPackageByCategorySortState: Sort
  customersListTableFilters: ICustomersListInfo
  customersListColumnsVisibility: IColumnsVisibility
  clientDetailReturnUrl: string
  communicationsListTableFilters: ICommunicationsListInfo
  communicationsListColumnsVisibility: IColumnsVisibility
  weeklyTargetExpandedShopsId: string[]
  weeklyTargetSelectedWeekNumber: number
  weeklyTargetSelectedRegion: string
  weeklyTargetSelectedDivision: string
  TargetRankingByLocationSelectedRegion: string
  TargetRankingByLocationSelectedDivision: string
  TargetRankingByRegionSelectedRegion: string
  TargetRankingByRegionSelectedDivision: string
  weeklyTargetRankingSortState: Sort
  arPaymentsSummaryFilter: IArInvoiceRequest
  packageUnperformedSummaryFilter: IPackageUnperformedRequest
  callsReportFilter: ITimePeriodRequest
  paymentsListTableFilters: Partial<IAdvancedTableInfo>
  paymentsListColumnsVisibility: IColumnsVisibility
  inventoriesListTableFilters: Partial<IAdvancedTableInfo>
  inventoriesListColumnsVisibility: IColumnsVisibility
  adminShopTiresExpandedShopsId: string[]
  promotionsExpandedShopsId: string[]
  promotionsUsersExpandedIds: string[]
  tiresSummaryFilter: ITiresRequest
  tiresListTableFilters: Partial<IAdvancedTableInfo>
  tiresListColumnsVisibility: IColumnsVisibility
  tiresListReturnUrl: string
  adminUserListSortState: Sort
  trackerLocationId: string
  lostTireLocationId: string
  communicationTypeFilter: ICommunicationTypeRequest
  weeklyCarCountsFilter: IWeeklyCarCountsRequest
  lostTiresListTableFilters: Partial<IAdvancedTableInfo>
  lostTiresListColumnsVisibility: IColumnsVisibility
  appVersion: string
  workOrdersSummaryFilter: IWorkOrdersSummaryRequest
  workOrdersSummarySortState: Sort
  workOrdersListTableFilters: Partial<IAdvancedTableInfo>
  workOrdersListColumnsVisibility: IColumnsVisibility
}

export type FilterStateKeys = keyof FiltersStateModel
