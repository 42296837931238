import { ITimePeriod, IUrlFilters } from '@lla-platform/core/core-data-access'
import { InvoicePackageGroup } from '../enums/invoice-package-group.enum'

export interface IGpPackageListRequest extends ITimePeriod {
  invoicePackageGroupBy: InvoicePackageGroup
  locationId?: string
  locationIds?: string[]
  serviceCategoryId?: string
}

export interface IInvoiceGpPackageInfo {
  total: number
  totalPercent: number
  formattedTotalPercent: number
}

export interface IInvoiceGpPackage {
  locationId?: string
  locationName?: string
  categoryId?: string
  categoryName?: string
  c1: IInvoiceGpPackageInfo
  c2: IInvoiceGpPackageInfo
  c3: IInvoiceGpPackageInfo
  c4: IInvoiceGpPackageInfo
  c5: IInvoiceGpPackageInfo
  total: IInvoiceGpPackageInfo
}

export interface IInvoiceGpPackageExtended extends IUrlFilters {
  locationId?: string
  locationName?: string
  categoryId?: string
  categoryName?: string
  c1Total: number
  c1Percent: number
  c1PercentFormatted: number
  c2Total: number
  c2Percent: number
  c2PercentFormatted: number
  c3Total: number
  c3Percent: number
  c3PercentFormatted: number
  c4Total: number
  c4Percent: number
  c4PercentFormatted: number
  c5Total: number
  c5Percent: number
  c5PercentFormatted: number
  totalsTotal: number
  totalsPercent: number
  totalsPercentFormatted: number
}

export interface IInvoiceGpPackageResponse {
  timePeriod: ITimePeriod
  items: IInvoiceGpPackage[]
  totalC1: IInvoiceGpPackageInfo
  totalC2: IInvoiceGpPackageInfo
  totalC3: IInvoiceGpPackageInfo
  totalC4: IInvoiceGpPackageInfo
  totalC5: IInvoiceGpPackageInfo
  grandTotals: IInvoiceGpPackageInfo
}

export interface IInvoiceGpPackageExtendedResponse {
  timePeriod: ITimePeriod
  items: IInvoiceGpPackageExtended[]
  grandTotal: IInvoiceGpPackageExtended
}
