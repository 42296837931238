<!-- Header -->
<div class="flex items-center justify-between flex-wrap pt-4 px-4 pb-1 md:pb-4 lg:px-5 lg:py-[22px]">
  <div class="pr-4">
    <div class="text-txt text-2xl leading-2.5xl font-semibold">{{ title }}</div>
    <div
      *ngIf="subtitleInfo"
      class="text-txt-secondary mt-[2px] text-base font-medium leading-lg flex items-center"
      [ngClass]="{ 'cursor-pointer': subtitleInfo.link }"
      [routerLink]="subtitleInfo.link"
    >
      <svg-icon *ngIf="subtitleInfo.icon" fontSize="12px" [key]="subtitleInfo.icon"></svg-icon>
      <span [ngClass]="{ 'ml-2': subtitleInfo.icon }"> {{ subtitleInfo.text }} </span>
    </div>
  </div>
  <div class="flex items-center justify-end flex-1">
    <div
      class="mr-[28px] text-primary-dark flex items-center cursor-pointer"
      *ngIf="!advancedFilter || numberOfAppliedFilters() > 0"
      (click)="showAppliedFiltersChanged()"
    >
      <svg-icon key="filters"></svg-icon>
      <span class="px-1 text-lg leading-xl font-medium">Filters </span>
      <div
        class="text-white bg-primary-dark text-base leading-lg font-semibold rounded-full w-3.5 h-3.5 flex items-center justify-center"
        *ngIf="numberOfAppliedFilters() > 0"
      >
        {{ numberOfAppliedFilters() }}
      </div>
    </div>
    <div
      class="mr-[28px] py-1 flex items-center cursor-pointer text-txt-secondary"
      [matMenuTriggerFor]="showHideColumnsMenu"
      #showHideColumnsMenuTrigger="matMenuTrigger"
    >
      <svg-icon key="columns"></svg-icon>
      <span class="ml-1 text-lg leading-xl font-medium">Columns</span>
    </div>

    <div
      (click)="showAddBookmarkModal()"
      class="py-1 flex items-center cursor-pointer text-txt-secondary"
    >
      <svg-icon key="bookmark"></svg-icon>
      <span class="md:w-[100px] mx-1 text-lg leading-xl font-medium">Bookmark</span>
    </div>
  </div>
</div>

<!-- Applied Filters List -->
<div
  *ngIf="advancedFilter && showAppliedFilters && numberOfAppliedFilters() > 0"
  class="flex items-center flex-wrap px-4 lg:px-5.5 pb-1 border-t border-gray-100"
  title=""
>
  <ng-container *ngFor="let filter of tableFilters | keyvalue">
    <ng-container *ngIf="filter.value?.items">
      <div
        *ngFor="let item of filter.value.items; let i = index"
        class="mt-1 flex items-center px-2 py-0.5 mr-2 rounded-0.5 border border-primary-dark"
      >
        <div class="pr-1 text-primary-dark text-base leading-lg font-medium">
          {{ generateFilterText(filter.key, item) }}
          <ng-container [ngSwitch]="filter.value.type">
            <ng-container *ngSwitchCase="'date'">{{ item.value | date : 'mediumDate' }}</ng-container>
            <ng-container *ngSwitchCase="'custom-date'">
              <ng-container *ngIf="item.value !== periodTypes.Custom">
                {{ periodTypeToLabel[item.value] }}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'enum'">
              {{
                item.value && item.value.length > 0
                  ? enumListToLabels(item.value, filter.value.enumItems)
                  : ''
              }}
            </ng-container>
            <ng-container *ngSwitchCase="'location-list'">
              {{
                !item.value || item.value.length < 1
                  ? ''
                  : item.value.length < 3
                  ? getShopNamesWithId(item.value)
                  : item.value.length + ' locations'
              }}
            </ng-container>
            <ng-container *ngSwitchCase="'category-list'">
              {{
                !item.value || item.value.length < 1
                  ? ''
                  : item.value.length < 3
                  ? getCategoryNamesWithId(item.value)
                  : item.value.length + ' categories'
              }}
            </ng-container>
            <ng-container *ngSwitchDefault>{{ item.value }}</ng-container>
          </ng-container>
        </div>
        <svg-icon
          class="cursor-pointer"
          (click)="removeFilterItem(filter.key, i)"
          key="close"
          color="#006EA8"
          fontSize="8px"
        ></svg-icon>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Loading Spinner -->
<div *ngIf="isLoading; else tableTemplate" class="mt-3 flex justify-center">
  <mat-progress-spinner [diameter]="80" mode="indeterminate"></mat-progress-spinner>
</div>

<!-- Table -->
<ng-template #tableTemplate>
  <div title="" class="w-full flex-1 overflow-auto px-0 beautyScroll border-t border-gray-100">
    <table mat-table [dataSource]="dataSource" class="shadow w-max">
      <ng-container *ngFor="let item of filterdDisplayedColumns; let i = index">
        <ng-container [matColumnDef]="item.key">
          <ng-container *ngIf="advancedFilter">
            <th
              class="cursor-pointer"
              mat-header-cell
              *matHeaderCellDef
              [matMenuTriggerFor]="tableFilters[item.key] ? filterMenu : null"
              #filterMenuTrigger="matMenuTrigger"
              [matMenuTriggerData]="{
                filter: tableFilters[item.key],
                item: item
              }"
              (click)="$event.stopPropagation()"
            >
              <div
                class="flex items-center"
                [ngClass]="{
                  'md:justify-end': item.align === 'right',
                  'md:justify-center': item.align === 'center',
                  'text-primary-dark': headerHasFilters(item.key)
                }"
              >
                <span>{{ item.label }}</span>
                <span class="pl-1 pb-[1px]" *ngIf="headerIsSorted(item.sortKey ?? item.key)">
                  <svg-icon
                    key="arrow-down-simple"
                    fontSize="7px"
                    [ngClass]="{ 'rotate-180': sortState.direction === 'asc' }"
                  ></svg-icon>
                </span>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="!advancedFilter">
            <th mat-header-cell *matHeaderCellDef>
              <lla-table-header
                [sortInfo]="[{ columnName: item.sortKey ?? item.key }]"
                [title]="item.label"
                [rightAlign]="item.align === 'right'"
                [sortState]="sortState"
                [sortable]="!item.disableSorting"
                (sortChanged)="sortState = $event; infoChanged(true)"
              ></lla-table-header>
            </th>
          </ng-container>

          <td
            mat-cell
            *matCellDef="let element"
            [attr.data-label]="item.label"
            (click)="tableClicked(i, element, item.key)"
            [ngClass]="{
              'clickable-cell': element.rowId !== totalRowUniqueId && item.clickable,
              'line-through': element?.isPayed
            }"
          >
            <div
              class="flex items-center"
              [ngClass]="{
                'md:justify-end': item.align === 'right',
                'md:justify-center': item.align === 'center'
              }"
            >
              <svg-icon
                fontSize="11px"
                key="file-copy"
                *ngIf="item.copyable && element.rowId !== totalRowUniqueId"
                ngxClipboard
                [cbContent]="element[item.displayKey ?? item.key]"
                (cbOnSuccess)="toastrService.success('Copied to clipboard successfully')"
                (click)="$event.preventDefault(); $event.stopPropagation()"
                class="mr-1"
              ></svg-icon>
              <a
                [routerLink]="item.link ? item.link(element) : undefined"
                [queryParams]="item.queryParams"
                class="flex items-center"
                [ngClass]="{
                  'md:justify-end': item.align === 'right',
                  'md:justify-center': item.align === 'center'
                }"
                [ngStyle]="{
                  maxWidth: item.maxWidth ? item.maxWidth + 'px' : 'auto',
                  'pointer-events': !item.link || !item.link(element) ? 'none' : 'auto'
                }"
              >
                <ng-container *ngIf="item.dollarSign">$</ng-container>
                <div [ngSwitch]="item.visualType">
                  <div
                    *ngSwitchCase="'html'"
                    class="whitespace-pre-wrap"
                    [innerHtml]="element[item.displayKey ?? item.key]"
                  ></div>
                  <div *ngSwitchCase="'date'">
                    {{ element[item.displayKey ?? item.key] | date : 'mediumDate' }}
                  </div>
                  <div *ngSwitchCase="'enum'">
                    {{ enumItemToLabel(item.key, element[item.displayKey ?? item.key]) }}
                  </div>
                  <div *ngSwitchCase="'icon'">
                    <svg-icon
                      *ngIf="element[item.key] !== null && element[item.key] !== undefined && item.icon"
                      [key]="item.icon.key(element[item.key])"
                      [fontSize]="item.icon.fontSize"
                      [color]="item.icon.color(element[item.key])"
                    ></svg-icon>
                  </div>
                  <div *ngSwitchCase="'badge'" class="pt-[2px] flex items-center">
                    <ng-container *ngIf="element[item.key] && element[item.key].length > 0">
                      <div
                        class="mr-0.5 px-1 py-[2px] border border-txt-secondary rounded-0.5 text-base leading-lg font-medium text-txt-secondary"
                        *ngFor="let badgeItem of element[item.key]"
                      >
                        {{ badgeItem }}
                      </div>
                    </ng-container>
                  </div>
                  <div *ngSwitchDefault>
                    <ng-container *ngIf="item.formattedKey">
                      {{ element[item.formattedKey] }}
                    </ng-container>
                    <ng-container *ngIf="!item.formattedKey">
                      {{
                        item.dollarSign && element[item.displayKey ?? item.key] !== 0
                          ? (element[item.displayKey ?? item.key] | number : '1.2-2')
                          : element[item.displayKey ?? item.key]
                      }}
                    </ng-container>
                  </div>
                </div>
              </a>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr
        class="advanced-table-header"
        mat-header-row
        *matHeaderRowDef="filterdDisplayedColumnKeys; sticky: true"
      ></tr>
      <tr
        mat-row
        [ngClass]="{ '!h-6': row.parentId, 'total-row': row.rowId === totalRowUniqueId }"
        *matRowDef="
          let row;
          let i = index;
          columns: filterdDisplayedColumnKeys
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: page,
                  totalItems: totalResults
                }
        "
      ></tr>
    </table>
  </div>

  <!-- Footer -->
  <lla-footer
    [numberOfRows]="totalResults"
    (exportBtnClicked)="downloadExportFileClicked.emit()"
    [isLoading]="isLoadingExportFile"
  >
    <!-- Pagination -->
    <div title="" *ngIf="totalPages > 1">
      <lla-pagination
        (pageChange)="page = $event; infoChanged()"
        [currentPage]="page"
        [totalPages]="totalPages"
      >
      </lla-pagination>
    </div>
  </lla-footer>
</ng-template>

<!-- Filter Menu -->
<mat-menu #filterMenu="matMenu" class="advanced-table-filter-mat-menu">
  <ng-template matMenuContent let-filter="filter" let-item="item">
    <div class="max-h-[500px]" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <lla-advanced-table-filter
        [filters]="filter"
        [columnName]="item.key"
        [sortColumnName]="item.sortKey"
        [sortable]="!item.disableSorting"
        [sortState]="sortState"
        (filtersChanged)="setFilters($event, item.key)"
        (sortChanged)="sortState = $event; infoChanged(true)"
      ></lla-advanced-table-filter>
    </div>
  </ng-template>
</mat-menu>

<!-- Show/Hide Columns Menu -->
<mat-menu #showHideColumnsMenu="matMenu">
  <ng-template matMenuContent>
    <form
      [formGroup]="showHideColumnsForm"
      class="flex flex-col px-3 pb-3"
      (click)="$event.stopPropagation()"
    >
      <div *ngFor="let item of displayedColumns" class="show-hide-container">
        <ng-container *ngIf="item.visualType !== 'none'">
          <input
            id="showHide{{ item.key }}"
            type="checkbox"
            class="show-hide-checkbox"
            [formControlName]="item.key"
          />
          <div class="show-hide-title" (click)="toggleShowHideCheckBox(item.key)">
            {{ item.label }}
          </div>
        </ng-container>
      </div>
    </form>
  </ng-template>
</mat-menu>
