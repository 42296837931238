import { IBaseShop, IFile } from '@lla-platform/core/core-data-access'

export interface IPromotionBase {
  promotionId: string
  title?: string
  isActive?: boolean
}

export interface IPromotionItem {
  id?: string
  isActive?: boolean
  title: string
  description: string
  code: string
  channel: string
  locationIds?: string[]
  promotionChannelId?: string
  files?: IFile[]
}

export interface IShopPromotionsItem {
  locationId: string
  locationName: string
  promotions: IPromotionItem[]
}

export interface IPromotionsResponse {
  locationsPromotions: IShopPromotionsItem[]
}

export interface IAdminPromotionItem {
  promotion: IPromotionItem
  locations: IBaseShop[]
}

export interface IAdminPromotionsResponse {
  items: IAdminPromotionItem[]
}
