import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { WorkOrderStage } from '../enums/work-orders-stage.emum'

export interface IWorkOrdersListItem extends IAdvancedTable {
  id: string
  contactId: string
  locationId: string
  locationName: string
  workOrderNumber: number
  creationTime: string
  companyName: string
  firstName: string
  lastName: string
  workOrderTotal: string
  stage: WorkOrderStage
}

export interface IWorkOrdersListResponse extends IPaginationFilteredResponse {
  workOrders: IWorkOrdersListItem[]
}
