import { IStringObject } from '@lla-platform/core/core-data-access'
import { ChartDataType } from '../enums/chart-data-type.enum'

export interface IChartInfo<T> {
  data: T[]
  type: ChartDataType
  shopName: string
  color?: string
}

export interface IBarChartColor {
  sales: IStringObject
  avgTicket: IStringObject
  carCount: IStringObject
  deferred: IStringObject
}
