export interface IInventorySummaryInfo {
  total: number
  formattedTotal: string
  totalQuantity: number
  formattedTotalQuantity: string
}

export interface IInventorySummaryItem {
  locationId: string
  locationName: string
  totalParts: IInventorySummaryInfo
  totalTiresAndAccessories: IInventorySummaryInfo
  totalTires: IInventorySummaryInfo
  locationGrandTotals: IInventorySummaryInfo
}

export interface IInventorySummaryExtended {
  locationId?: string
  locationName?: string

  totalPartsTotal: number
  totalPartsQuantity: number
  totalTiresAndAccessoriesTotal: number
  totalTiresAndAccessoriesQuantity: number
  totalTiresTotal: number
  totalTiresQuantity: number
  grandTotalsTotal: number
  grandTotalsQuantity: number

  formattedTotalPartsTotal: string
  formattedTotalPartsQuantity: string
  formattedTotalTiresAndAccessoriesTotal: string
  formattedTotalTiresAndAccessoriesQuantity: string
  formattedTotalTiresTotal: string
  formattedTotalTiresQuantity: string
  formattedGrandTotalsTotal: string
  formattedGrandTotalsQuantity: string
}

export interface IInventorySummaryResponse {
  inventories: IInventorySummaryItem[]
  grandTotalParts: IInventorySummaryInfo
  grandTotalTiresAndAccessories: IInventorySummaryInfo
  grandTotalTires: IInventorySummaryInfo
  grandTotals: IInventorySummaryInfo
}

export interface IInventorySummaryExtendedResponse {
  inventories: IInventorySummaryExtended[]
  grandTotal: IInventorySummaryExtended
}
