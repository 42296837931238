import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { MissedReasonType } from '../enums/chapter-code-type.enum'
import { LostTireCallType } from '../enums/lost-tire-call-type.enum'
import { LostTireSoldType } from '../enums/lost-tire-sold-type.enum'

export interface ILostTiresListItem extends IAdvancedTable {
  locationId: string
  locationName: string
  brand: string
  model: string
  size: string
  missedReason: MissedReasonType
  other: string
  notes: string
  created: Date
  email: string
  callType: LostTireCallType
  soldType: LostTireSoldType
}

export interface ILostTiresListResponse extends IPaginationFilteredResponse {
  tires: ILostTiresListItem[]
}
