export interface ICustomerGeoLocationRequest {
  locationId: string
  radius: number
}

export interface ICustomerGeoLocationItem {
  contactId: string
  name: string
  address: string
  latitude: number
  longitude: number
}

export interface ICustomerGeoLocationResponse {
  contacts: ICustomerGeoLocationItem[]
}
