import { Component, Input, OnInit } from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ISelectItem } from '../input/input.interface'

@UntilDestroy()
@Component({
  selector: 'lla-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() controlName: string
  @Input() item?: ISelectItem
  @Input() extraClass = ''
  @Input() extraLabelClass = ''
  @Input() label = ''
  @Input() disabled = false
  @Input() size = 14

  formControl: UntypedFormControl

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
  }

  toggleCheckbox(value?: string) {
    if (this.disabled) {
      return
    }
    if (value && this.item) {
      const temp: string[] = this.formControl?.value ?? []
      const formValue = [...temp]
      const foundIndex = formValue.findIndex((el) => el === value)
      if (foundIndex < 0) {
        formValue.push(value)
      } else {
        formValue.splice(foundIndex, 1)
      }
      this.formControl?.setValue(formValue)
    } else {
      this.formControl?.setValue(!this.formControl?.value)
    }
  }
}
