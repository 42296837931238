<div [ngClass]="{ 'min-w-[280px]': filters.type === 'custom-date' }">
  <div class="p-3 pb-2 flex justify-end items-center">
    <ng-container *ngIf="sortable">
      <div
        class="mr-0.5 sort-pill"
        [ngClass]="{
          'sort-pill-active':
            sortState &&
            (sortColumnName ? sortState.active === sortColumnName : sortState.active === columnName) &&
            sortState.direction === 'desc'
        }"
        (click)="
          sortChanged.emit(
            sortStateService.update(
              { active: sortColumnName ?? columnName, direction: 'desc' },
              sortState
            )
          )
        "
      >
        <div class="sort-pill-title">High to Low</div>
      </div>
      <div
        class="sort-pill"
        [ngClass]="{
          'sort-pill-active':
            sortState &&
            (sortColumnName ? sortState.active === sortColumnName : sortState.active === columnName) &&
            sortState.direction === 'asc'
        }"
        (click)="
          sortChanged.emit(
            sortStateService.update(
              { active: sortColumnName ?? columnName, direction: 'asc' },
              sortState
            )
          )
        "
      >
        <div class="sort-pill-title">Low to High</div>
      </div>
    </ng-container>
  </div>

  <form class="p-3 pt-0" [formGroup]="form" (ngSubmit)="onSubmit()">
    <lla-input
      *ngIf="!customFilterTypes.includes(filters.type)"
      class="w-full"
      controlName="aggregator"
      type="select"
      [icon]="'arrow-down'"
      iconSize="15px"
      label="Aggregator"
      size="small"
      [items]="[
        {
          label: 'Match All',
          value: 'And'
        },
        {
          label: 'Match Any',
          value: 'Or'
        }
      ]"
    ></lla-input>

    <div
      class="py-2 border-t border-gray-200"
      [ngClass]="{ '!border-t-0 !pt-0': i === 0 }"
      formArrayName="items"
      *ngFor="let itemForm of $any(itemsControl['controls']); let i = index"
    >
      <ng-container *ngIf="!noFilterTypes.includes(filters.type)" [formGroup]="itemForm">
        <ng-container *ngIf="!customFilterTypes.includes(filters.type); else customFilterTemplate">
          <lla-input
            class="w-full"
            controlName="operator"
            type="select"
            [icon]="'arrow-down'"
            iconSize="15px"
            label="Operator"
            size="small"
            [items]="operators"
          ></lla-input>
          <lla-input
            controlName="value"
            [type]="$any(filters.type)"
            size="small"
            label="Value"
          ></lla-input>
        </ng-container>

        <ng-template #customFilterTemplate>
          <div [ngSwitch]="filters.type">
            <ng-container *ngSwitchCase="'enum'">
              <ng-container *ngTemplateOutlet="enumFilterTemplate"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'location-list'">
              <ng-container
                *ngTemplateOutlet="enumFilterTemplate; context: { withFilter: true }"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'category-list'">
              <ng-container
                *ngTemplateOutlet="enumFilterTemplate; context: { withFilter: true }"
              ></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'enum-radio'">
              <ng-container *ngTemplateOutlet="enumRadioFilterTemplate"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'custom-date'">
              <ng-container *ngTemplateOutlet="customDateTemplate"></ng-container>
            </ng-container>
          </div>
        </ng-template>

        <ng-template #enumFilterTemplate let-withFilter="withFilter">
          <div *ngIf="withFilter">
            <input
              class="search-input"
              type="text"
              placeholder="Search..."
              (input)="enumSearchInputChanged($any($event))"
            />
          </div>
          <div
            *ngFor="let item of selectedEnumItems ?? []"
            class="flex items-center mb-1 cursor-pointer"
            (click)="addEnumToFormValue(itemForm.get('value'), item.value)"
          >
            <input
              type="checkbox"
              class="form-checkbox mr-2 w-3 h-3 rounded-0.5 text-primary-dark focus:outline-none focus:ring-transparent focus:shadow-none"
              [checked]="itemForm.get('value')?.value?.includes(item.value)"
            />
            <div class="text-lg leading-xl text-txt-secondary font-medium">
              {{ item.label }}
            </div>
          </div>
        </ng-template>

        <ng-template #enumRadioFilterTemplate>
          <div
            *ngFor="let item of filters.enumItems ?? []"
            class="flex items-center pr-6 mb-1 cursor-pointer"
          >
            <lla-radio [item]="item" controlName="value"></lla-radio>
          </div>
        </ng-template>

        <ng-template #customDateTemplate>
          <div
            *ngFor="let item of filters.enumItems ?? []"
            class="flex items-center pr-6 mb-1 cursor-pointer"
          >
            <lla-radio
              [item]="item"
              controlName="value"
              (click)="itemForm.get('operator')?.setValue('Period'); itemForm.get('extraValue')?.reset()"
            ></lla-radio>
          </div>
          <lla-input-range-date
            *ngIf="itemForm.get('value')?.value === periodType.Custom"
            label="Date Range"
            icon="calendar"
            iconSize="15px"
            class="w-full md:w-auto md:max-w-[200px]"
            size="small"
            [initialValue]="itemForm.get('extraValue')?.value"
            (valueChanged)="customDateChanged(itemForm.get('extraValue'), $event)"
          ></lla-input-range-date>
        </ng-template>

        <div
          class="mt-1 mb-0.5 flex items-center justify-center"
          *ngIf="itemsControl['controls'].length - 1 === i && !customFilterTypes.includes(filters.type)"
        >
          <div (click)="addNewRule()" class="flex items-center justify-center text-txt cursor-pointer">
            <svg-icon key="plus" fontSize="12px"></svg-icon>
            <span class="pl-1 leading-xl text-lg font-medium">Add Rule</span>
          </div>
          <div
            *ngIf="i !== 0"
            (click)="removeRule(i)"
            class="ml-2 flex items-center justify-center text-base text-txt cursor-pointer"
          >
            <svg-icon key="close" fontSize="11px"></svg-icon>
            <span class="pl-1 leading-xl text-lg font-medium">Remove Rule</span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-col">
      <lla-button class="w-full" variant="black" type="submit" size="small"> Filter </lla-button>
      <lla-button (click)="clearFilters()" class="w-full mt-1" variant="black-invert" size="small">
        Clear Filter
      </lla-button>
    </div>
  </form>
</div>
