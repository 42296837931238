export interface ISalesReportItem {
  month: string
  sales: number | null
  carCount: number | null
  avgTicket: number | null
  deferred?: number | null
}

export interface ISalesReportExtendedItem extends ISalesReportItem {
  date: {
    month: string
    year: string
  }
}

export interface ISalesReportStaticData {
  [key: string]: ISalesReportItem[]
}

export interface ISalesReportStaticExtendedData {
  [key: string]: ISalesReportExtendedItem[]
}

export interface ISalesReportDetails {
  locationId: string
  locationName: string
  locationNickname: string
  items: ISalesReportItem[]
}

export interface ISalesReportResponse {
  locations: ISalesReportDetails[]
}
