export interface IBaseInvoiceDetail {
  locationId: string
  locationName: string
  invoiceNumber: number
  invoiceTime: string
  workOrder: number
  serviceAdvisor: string
  technician: string
}

export interface IInvoiceSummary {
  partsTotalFormatted: string
  laborTotalFormatted: string
  otherChargeTotalFormatted: string
  grandTotalFormatted: string
  totalWithoutTaxFormatted: string
}

export interface IInvoiceVehicle {
  plate: string
  description: string
  vin: string
  odometer: number
}

export interface IServicePackageLine {
  description: string
  quantity?: number
  priceFormatted: string
  priceUnit: string
  totalFormatted: string
  otherChargeCode: string
}

export interface IServicePackage {
  header: string
  description: string
  subTotalFormatted: string
  subTotalDiscountFormatted: string
  lines: IServicePackageLine[]
}

export interface IInvoiceContact {
  contactId: string
  firstName: string
  lastName: string
  company: string
}

export interface IInvoiceDetailResponse {
  contact?: IInvoiceContact
  invoice: IBaseInvoiceDetail
  summary: IInvoiceSummary
  vehicle: IInvoiceVehicle
  concern: IServicePackage[]
  service: IServicePackage[]
}
