import { TargetType } from '@lla-platform/targets/targets-data-access'

export interface ITire {
  label: string
  type: TargetType
  tier1: number
  formattedTier1: string
  tier2: number
  formattedTier2: string
  tier3: number
  formattedTier3: string
  orderNo: number
  isVisible: boolean
}

export interface IAdminShopTiersItem {
  locationId: string
  name: string
  targets: ITire[]
}

export interface IAdminShopTiersResponse {
  locations: IAdminShopTiersItem[]
}

export interface IAdminShopTiersUpdate {
  locationTargets: IAdminShopTiersItem
}
