export interface ISelectItem {
  value: any
  label: string
  disabled?: boolean
  extraInfo?: any
}

export interface IInputErrorMessages {
  type: string
  message: string
}

export interface IRangeDateValue {
  from?: Date | string
  to?: Date | string
}
