import {
  IAdvancedTable,
  IAdvancedTableBaseInfo,
  IPaginationBaseRequest,
  IPaginationFilteredResponse
} from '@lla-platform/core/core-data-access'

export interface IInventoriesListInfo {
  tableInfo?: IAdvancedTableBaseInfo
  filters?: IInventoriesListRequest
}

export interface IInventoriesListRequest extends IPaginationBaseRequest {
  partNumber?: string
  description?: string
  manufacturer?: string
  size?: string
  partTerminologyText?: string
  inStock?: number
  price?: number
  serviceCategory?: string
  shopName?: string
  total?: number
}

export interface IInventoriesListItem extends IAdvancedTable {
  id: string
  partNumber: string
  description: string
  manufacturer: string
  size?: string
  partTerminologyText: string
  inStock: number
  inStockFormatted: string
  price: number
  priceFormatted: string
  serviceCategory: string
  locationName: string
  total: number
  totalFormatted: string
  isTire: boolean
}

export interface IInventoriesListResponse extends IPaginationFilteredResponse {
  inventories: IInventoriesListItem[]
}
