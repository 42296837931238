export interface ICommunicationTypeRequest {
  locationIds?: string[]
}

export interface ICommunicationTypeInfo {
  total: number
  totalPercent: number
}

export interface ICommunicationType {
  locationId: string
  locationName: string
  emailAndPhone: ICommunicationTypeInfo
  email: ICommunicationTypeInfo
  phone: ICommunicationTypeInfo
  totals: ICommunicationTypeInfo
}

export interface ICommunicationTypeExtended {
  locationId?: string
  locationName?: string
  emailAndPhoneTotal: number
  emailAndPhonePercent: number
  emailTotal: number
  emailPercent: number
  phoneTotal: number
  phonePercent: number
  totalsTotal: number
  totalsPercent: number
}

export interface ICommunicationsTypeResponse {
  contactCommunicationTypes: ICommunicationType[]
  totalEmailAndPhone: ICommunicationTypeInfo
  totalEmail: ICommunicationTypeInfo
  totalPhone: ICommunicationTypeInfo
  grandTotals: ICommunicationTypeInfo
}

export interface ICommunicationsTypeExtendedResponse {
  CommunicationTypes: ICommunicationTypeExtended[]
  grandTotal: ICommunicationTypeExtended
}
