import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'

export interface IPaymentsListItem extends IAdvancedTable {
  accountName: string
  accountId: string
  locationName: string
  locationId: string
  description: string
  amount: number
  date: Date
}

export interface IPaymentsListResponse extends IPaginationFilteredResponse {
  payments: IPaymentsListItem[]
}
