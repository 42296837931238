import { IGenericObject, TierType } from '@lla-platform/core/core-data-access'

export interface IWeeklyCarCountsRequest {
  locationIds?: string[]
}

export interface IWeeklyCarCountsPeriodItem {
  carCount: number
  carCountTier: TierType
  weekNumber: number
  period: string
  startOfWeek: Date
  endOfWeek: Date
}

export interface IWeeklyCarCountsItem {
  locationId: string
  locationName: string
  periods: IWeeklyCarCountsPeriodItem[]
}

export interface IWeeklyCarCountsTotalItem {
  amount: number
  weekNumber: number
  period: string
  startOfWeek: Date
  endOfWeek: Date
}

export interface IWeeklyCarCountsGraph {
  missed: number
  tier1: number
  tier2: number
  tier3: number
  weekNumber: number
  period: string
  startOfWeek: Date
  endOfWeek: Date
}

export interface IWeeklyCarCountsResponse {
  locations: IWeeklyCarCountsItem[]
  totals: IWeeklyCarCountsTotalItem[]
  graphs: IWeeklyCarCountsGraph[]
}

export interface IWeeklyCarCountsInfo {
  locations: IGenericObject[]
  totals: IGenericObject
  graphs: IGenericObject[]
  allPeriods: string[]
}
