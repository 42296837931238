import { ISelectItem } from '@lla-platform/core/core-ui'

export enum WorkOrderStage {
  EstimateCompleted = 'EstimateCompleted',
  WorkCompleted = 'WorkCompleted',
  ScheduledWork = 'ScheduledWork',
  Unassigned = 'Unassigned',
  OnHold = 'OnHold',
  InspectionInProgress = 'InspectionInProgress',
  WorkAuthorized = 'WorkAuthorized',
  InspectionCompleted = 'InspectionCompleted'
}

export const WorkOrderStageItems: ISelectItem[] = [
  {
    label: 'Estimate Completed',
    value: WorkOrderStage.EstimateCompleted
  },
  {
    label: 'Work Completed',
    value: WorkOrderStage.WorkCompleted
  },
  {
    label: 'Scheduled Work',
    value: WorkOrderStage.ScheduledWork
  },
  {
    label: 'Unassigned',
    value: WorkOrderStage.Unassigned
  },
  {
    label: 'On Hold',
    value: WorkOrderStage.OnHold
  },
  {
    label: 'Inspection in Progress',
    value: WorkOrderStage.InspectionInProgress
  },
  {
    label: 'Work Authorized',
    value: WorkOrderStage.WorkAuthorized
  },
  {
    label: 'Inspection Completed',
    value: WorkOrderStage.InspectionCompleted
  }
]
