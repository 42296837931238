export interface IRequestPasswordReset {
  userName: string
}

export interface IResetPassword {
  token: string
  newPassword: string
}

export interface IUpdatePasswordRequest {
  oldPassword: string
  newPassword: string
}
