import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IInvoiceGrossProfitRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface IInvoiceGrossProfitInfo {
  total: number
  totalPercent: number
  formattedTotalPercent: number
}

export interface IInvoiceGrossProfit {
  locationId: string
  locationName: string
  c1: IInvoiceGrossProfitInfo
  c2: IInvoiceGrossProfitInfo
  c3: IInvoiceGrossProfitInfo
  c4: IInvoiceGrossProfitInfo
  c5: IInvoiceGrossProfitInfo
  total: IInvoiceGrossProfitInfo
}

export interface IInvoiceGrossProfitExtended extends IUrlFilters {
  locationId?: string
  locationName?: string
  c1Total: number
  c1Percent: number
  c1PercentFormatted: number
  c1PercentGlobal?: number
  c2Total: number
  c2Percent: number
  c2PercentFormatted: number
  c2PercentGlobal?: number
  c3Total: number
  c3Percent: number
  c3PercentFormatted: number
  c3PercentGlobal?: number
  c4Total: number
  c4Percent: number
  c4PercentFormatted: number
  c4PercentGlobal?: number
  c5Total: number
  c5Percent: number
  c5PercentFormatted: number
  c5PercentGlobal?: number
  totalsTotal: number
  totalsPercent: number
  totalsPercentFormatted: number
  totalsPercentGlobal?: number
}

export interface IInvoiceGrossProfitResponse {
  timePeriod: ITimePeriod
  invoiceGrossProfits: IInvoiceGrossProfit[]
  totalC1: IInvoiceGrossProfitInfo
  totalC2: IInvoiceGrossProfitInfo
  totalC3: IInvoiceGrossProfitInfo
  totalC4: IInvoiceGrossProfitInfo
  totalC5: IInvoiceGrossProfitInfo
  grandTotals: IInvoiceGrossProfitInfo
}

export interface IInvoiceGrossProfitExtendedResponse {
  timePeriod: ITimePeriod
  invoiceGrossProfits: IInvoiceGrossProfitExtended[]
  grandTotal: IInvoiceGrossProfitExtended
}
