import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IDeferredTypeRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface IDeferredTypeInfo {
  total: number
  totalPercent: number
}

export interface IDeferredTypeItem {
  locationId: string
  locationName: string
  totalServices: number
  deferredServices: IDeferredTypeInfo
  deferredConcern: IDeferredTypeInfo
  deferredInspection: IDeferredTypeInfo
  totals: IDeferredTypeInfo
}

export interface IDeferredTypeExtended extends IUrlFilters {
  locationId?: string
  locationName?: string
  totalServices?: number
  deferredServicesTotal: number
  deferredServicesPercent: number
  deferredConcernTotal: number
  deferredConcernPercent: number
  deferredInspectionTotal: number
  deferredInspectionPercent: number
  totalsTotal: number
  totalsPercent: number
}

export interface IDeferredTypeResponse {
  timePeriod: ITimePeriod
  deferredTypes: IDeferredTypeItem[]
  totalDeferredServices: IDeferredTypeInfo
  totalDeferredConcerns: IDeferredTypeInfo
  totalDeferredInspections: IDeferredTypeInfo
  grandTotals: IDeferredTypeInfo
}

export interface IDeferredTypeExtendedResponse {
  timePeriod: ITimePeriod
  deferredTypes: IDeferredTypeExtended[]
  grandTotal: IDeferredTypeExtended
}
