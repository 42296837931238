<div class="flex items-center {{ extraClass }}">
  <input
    type="checkbox"
    class="form-checkbox mr-1 rounded-[2px] text-primary-dark focus:outline-none focus:ring-transparent focus:shadow-none"
    [ngStyle]="{ width: size + 'px', height: size + 'px' }"
    (click)="changed.emit(!value)"
    [checked]="value"
    [disabled]="disabled"
  />
  <div
    class="leading-xl text-txt-secondary font-medium cursor-pointer {{ extraLabelClass }}"
    [ngClass]="{ '!text-txt-disabled': disabled }"
    [ngStyle]="{ fontSize: size + 'px' }"
    (click)="changed.emit(!value)"
  >
    {{ label }}
  </div>
</div>
