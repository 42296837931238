import { IBaseShop, IUrlFilterItem, TierType } from '@lla-platform/core/core-data-access'
import { TargetType } from '../enums/target-type.enum'

export interface IShopTargetWeekBase {
  monday: number
  tuesday: number
  wednesday: number
  thursday: number
  friday: number
  saturday: number
  sunday: number
}

export interface IShopTargetWeekInfo extends IShopTargetWeekBase {
  mondayInvoiced?: number
  mondayCost?: number
  mondayFormatted: string
  tuesdayInvoiced?: number
  tuesdayCost?: number
  tuesdayFormatted: string
  wednesdayInvoiced?: number
  wednesdayCost?: number
  wednesdayFormatted: string
  thursdayInvoiced?: number
  thursdayCost?: number
  thursdayFormatted: string
  fridayInvoiced?: number
  fridayCost?: number
  fridayFormatted: string
  saturdayInvoiced?: number
  saturdayCost?: number
  saturdayFormatted: string
  sundayInvoiced?: number
  sundayCost?: number
  sundayFormatted: string
}

export interface IShopTargetItem {
  objective: TargetType
  currentTier: TierType
  tier1Target: number
  tier2Target: number
  tier3Target: number
  tier1TargetFormatted: string
  tier2TargetFormatted: string
  tier3TargetFormatted: string
  current: number
  currentFormatted: string
  tier1Percent: number
  tier1PercentFormatted: string
  tier2Percent: number
  tier2PercentFormatted: string
  tier3Percent: number
  tier3PercentFormatted: string
  orderNo?: number
  isVisible?: boolean
  week?: IShopTargetWeekInfo
}

export interface IShopWeekTarget {
  location: IBaseShop
  items: IShopTargetItem[]
  link?: string
  queryParams?: IUrlFilterItem
}

export interface IWeekTargetResponse {
  locations: IShopWeekTarget[]
}
