import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface ICustomerRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

export interface ICustomerTypeInfo {
  total: number
  totalPercent: number
}

export interface ICustomerType {
  locationId: string
  locationName: string
  new: ICustomerTypeInfo
  friends: ICustomerTypeInfo
  advocates: ICustomerTypeInfo
  totals: ICustomerTypeInfo
}

export interface ICustomerTypeExtended extends IUrlFilters {
  locationId?: string
  locationName?: string
  newTotal: number
  newPercent: number
  friendsTotal: number
  friendsPercent: number
  advocatesTotal: number
  advocatesPercent: number
  totalsTotal: number
  totalsPercent: number
}

export interface ICustomersTypeResponse {
  timePeriod: ITimePeriod
  contactTypes: ICustomerType[]
  totalNew: ICustomerTypeInfo
  totalFriends: ICustomerTypeInfo
  totalAdvocates: ICustomerTypeInfo
  grandTotals: ICustomerTypeInfo
}

export interface ICustomersTypeExtendedResponse {
  timePeriod: ITimePeriod
  customerTypes: ICustomerTypeExtended[]
  grandTotal: ICustomerTypeExtended
}

export interface ICustomerUpdateRequest {
  contactLocationId: string
  noCalls: boolean
}

export interface ICustomerGetItem {
  contactId: string
  locationId: string
  firstName: string
  lastName: string
  companyName: string
  phone: string
  email: string
  locationName: string
  noCalls: boolean
}

export interface ICustomerGetResponse {
  contactInfo: ICustomerGetItem
}
