import { Sort } from '@angular/material/sort'
import { ISelectItem } from '@lla-platform/core/core-ui'

export type TableOperator =
  | 'LessThan'
  | 'LessThanOrEqual'
  | 'Equals'
  | 'NotEquals'
  | 'GreaterThanOrEqual'
  | 'GreaterThan'
  | 'Contains'
  | 'StartsWith'
  | 'EndsWith'
  | 'Period'

export type TableFilterType =
  | 'number'
  | 'text'
  | 'booleanString'
  | 'date'
  | 'guid'
  | 'enum'
  | 'enum-radio'
  | 'custom-date'
  | 'location-list'
  | 'category-list'

export type VisualType = 'icon' | 'badge' | 'date' | 'enum' | 'normal' | 'html' | 'none'

export type AggregatorType = 'And' | 'Or'

export interface ITableFilterItem {
  operator: TableOperator
  value: any
  extraValue?: any
}

export interface ITableFilterOption {
  type: TableFilterType
  aggregator?: AggregatorType
  items?: ITableFilterItem[]
  enumItems?: ISelectItem[]
}

export interface ITableFilter {
  [key: string]: ITableFilterOption
}

export interface IAdvancedTable {
  items?: IAdvancedTable[]
  rowId: string
  parentId?: string
}

export interface IAdvancedTableColumn {
  key: string
  formattedKey?: string
  sortKey?: string
  displayKey?: string
  label: string
  hide?: boolean
  align?: 'left' | 'right' | 'center'
  dollarSign?: boolean
  type: TableFilterType
  visualType?: VisualType
  icon?: {
    key: (data?: any) => string
    color: (data?: any) => string
    fontSize: string
  }
  disableSorting?: boolean
  maxWidth?: number
  clickable?: boolean
  copyable?: boolean
  link?: (item?: any) => string | undefined
  queryParams?: any
}

export interface IAdvancedTableBaseInfo {
  page: number
  sortState: Sort
}

export interface IAdvancedTableInfo extends IAdvancedTableBaseInfo {
  filters: ITableFilter
}

export interface IColumnsVisibility {
  [key: string]: boolean
}

export interface ISubtitleInfo {
  text: string
  icon?: string
  link?: string
}

export interface IAdvancedTableClickEvent<T> {
  element: T
  columnName: string
}
