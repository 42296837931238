import { LostTireCallType } from '../enums/lost-tire-call-type.enum'
import { LostTireSoldType } from '../enums/lost-tire-sold-type.enum'

export interface ILostTireReason {
  reason: string
  reasonName: string
}

export interface ILostTireResponse {
  reasons: ILostTireReason[]
  brands: string[]
}

export interface ILostTireRequest {
  locationId: string
  brand: string
  model: string
  size: string
  missedReason: string
  other: string
  notes: string
  callType: LostTireCallType
  soldType: LostTireSoldType
}
