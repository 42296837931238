<div
  class="mr-3 text-primary-dark flex items-center cursor-pointer"
  (click)="filterButtonClicked.emit()"
>
  <svg-icon key="filters" fontSize="14px"></svg-icon>
  <span class="pl-1 pr-0.5 text-base leading-lg font-medium">Filters</span>
  <div
    class="text-white bg-primary-dark text-[10px] leading-[10px] font-semibold rounded-full w-[18px] h-[18px] flex items-center justify-center"
    *ngIf="numberOfFilters && numberOfFilters > 0"
  >
    {{ numberOfFilters }}
  </div>
</div>
