import { inject, Injectable } from '@angular/core'
import { GetCurrentUser, IUser, UserAccountState } from '@lla-platform/users/users-data-access'
import { Store } from '@ngxs/store'
import { catchError, map, Observable, of, switchMap, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class CurrentUserGuard {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.currentUser$.pipe(
      switchMap((user) => {
        return user
          ? of(user)
          : this.store.dispatch(new GetCurrentUser()).pipe(map((state: any) => state.user))
      }),
      catchError(() => {
        return of(null)
      }),
      take(1),
      map(() => true)
    )
  }
}
