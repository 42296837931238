import { IReviewItem } from './reviews.interface'

export interface IReplySugestionRequest extends IReviewItem {
  googleLocationId: string
  message: string
  extraMessage: string
}

export interface IReplySugestionResponse {
  message: string
}
