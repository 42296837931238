import { IAdvancedTableInfo } from '@lla-platform/core/core-data-access'
import { IWorkOrdersSummaryRequest } from '../../interfaces/work-orders-summary.interface'

export class GetWorkOrdersSummary {
  static readonly type = '[workOrders] Get Work Orders Summary'
  constructor(public payload: IWorkOrdersSummaryRequest) {}
}
export class GetWorkOrdersList {
  static readonly type = '[workOrders] Get Work Orders List'
  constructor(public info: IAdvancedTableInfo) {}
}
