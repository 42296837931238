import { ContactType } from '@lla-platform/core/core-data-access'
import { CommunicationSource } from '../enums/communication-source.enum'
import { CommunicationStatus } from '../enums/communication-status.enum'
import { CommunicationType } from '../enums/communication-type.enum'
import { SatisfactionType } from '../enums/satisfaction-type.enum'
import { TagType } from '../enums/tag-type.enum'
import { WorkCategoryType } from '../enums/work-category-type.enum'

export interface ICommunicationRequest {
  locationId: string
  invoiceId?: string
  type: CommunicationType
  source?: CommunicationSource
  status?: CommunicationStatus
  workCategory?: WorkCategoryType[]
  satisfaction?: SatisfactionType
  contactType: ContactType
  scheduleAt?: Date
  customerComments: string
  customerServiceNotes: string
  storeManagerNotes: string
  regionalManagerNotes: string
  otherComments?: string
  tags?: TagType[]
}

export interface IUpdateCommunicationRequest extends ICommunicationRequest {
  communicationId: string
}

export interface ICommunicationResponse {
  locationId: string
  type: CommunicationType
  source: CommunicationSource
  status: CommunicationStatus
  workCategory: WorkCategoryType
  satisfaction: SatisfactionType
  contactType: ContactType
  scheduleAt: Date
  customerComments: string
  customerServiceNotes: string
  storeManagerNotes: string
  regionalManagerNotes: string
  otherComments: string
  contactId: string
  invoiceId: string
  tags: TagType[]
}

export interface ICommunicationHistoryItem {
  communicationId: string
  callDate: Date
  agent: string
  source: CommunicationSource
  status: CommunicationStatus
  statusIcon?: {
    icon: string
    color: string
  }
}

export interface ICommunicationHistoryResponse {
  items: ICommunicationHistoryItem[]
}
