<div
  class="bg-white border-t border-gray-100 w-full pr-4 pl-3 md:pr-[28px] py-[6px] flex items-center justify-between"
>
  <div class="flex-1">
    <ng-content></ng-content>
  </div>
  <div class="flex items-center">
    <div class="pr-3.5 text-txt-secondary text-lg leading-xl font-medium">
      Showing {{ numberOfRows }} rows
    </div>
    <ng-container *ngIf="showDownloadButton">
      <svg-icon
        *ngIf="!isLoading"
        class="cursor-pointer"
        (click)="exportBtnClicked.emit()"
        key="download-cloud"
        color="#5E5E5E"
        fontSize="14px"
      ></svg-icon>
      <lla-loading-spinner class="w-[14px]" *ngIf="isLoading" [size]="3"></lla-loading-spinner>
    </ng-container>
  </div>
</div>
