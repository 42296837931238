import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'

export interface IGrossProfitListItem extends IAdvancedTable {
  invoiceId?: string
  locationId?: string
  locationName: string
  invoiceNumber?: number
  invoiceDate?: Date
  totalWithTax: number
  formattedTotalWithTax: string
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  invoiced: number
  formattedInvoiced: string
  cost: number
  formattedCost: string
  margin: number
  formattedMargin: string
  ipInspections: number
  ipGeneralCredits: number
  ipDeferredConcerns: number
  ipDeferredInspections: number
  ipDeferredServices: number
  ipServices: number
  ipConcerns: number
}

export interface IGrossProfitListTotal {
  ipInspections: number
  ipGeneralCredits: number
  ipDeferredConcerns: number
  ipDeferredInspections: number
  ipDeferredServices: number
  ipServices: number
  ipConcerns: number
  totalWithTax: number
  formattedTotalWithTax: string
  totalWithoutTax: number
  formattedTotalWithoutTax: string
  invoiced: number
  formattedInvoiced: string
  cost: number
  formattedCost: string
  margin: number
  formattedMargin: string
}

export interface IGrossProfitListResponse extends IPaginationFilteredResponse {
  invoices: IGrossProfitListItem[]
  totals: IGrossProfitListTotal
}
