export interface ICategoryItem {
  id: string
  name: string
}

export interface ICategoryResponse {
  categories: ICategoryItem[]
}

export interface IServiceCategoryThresoldItem {
  thresold: number
  id: string
  name: string
}

export interface IServiceCategoryThresoldsRequest {
  items: IServiceCategoryThresoldItem[]
}

export interface IServiceCategoryThresoldsResponse {
  items: IServiceCategoryThresoldItem[]
}
