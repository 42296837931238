import { Injectable } from '@angular/core'
import {
  HttpService,
  IAdvancedTableInfo,
  IExportFileResponse,
  IPaginationFilteredRequest,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IWorkOrdersSummaryRequest,
  IWorkOrdersSummaryResponse
} from '../interfaces/work-orders-summary.interface'
import { WORK_ORDERS_API_URL } from './work-orders-api-routes'
import { IWorkOrdersListResponse } from '../interfaces/work-orders-list.interface'

@Injectable({
  providedIn: 'root'
})
export class WorkOrdersService {
  constructor(private http: HttpService, private tableFilterService: TableFilterService) {}

  getWorkOrdersSummary(payload: IWorkOrdersSummaryRequest): Observable<IWorkOrdersSummaryResponse> {
    return this.http.post(WORK_ORDERS_API_URL.summary, payload)
  }

  exportWorkOrdersSummary(payload: IWorkOrdersSummaryRequest): Observable<IExportFileResponse> {
    return this.http.post(WORK_ORDERS_API_URL.exportSummary, payload)
  }

  getWorkOrdersList(payload: IPaginationFilteredRequest): Observable<IWorkOrdersListResponse> {
    return this.http.post(WORK_ORDERS_API_URL.list, payload)
  }

  exportWorkOrdersList(payload: IAdvancedTableInfo): Observable<IExportFileResponse> {
    return this.http.post(
      WORK_ORDERS_API_URL.exportList,
      this.tableFilterService.createRequestPayload(payload)
    )
  }
}
