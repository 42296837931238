import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { ChapterCodeType } from '../enums/chapter-code-type.enum'

export interface IGpPackageListItem extends IAdvancedTable {
  invoiceId?: string
  locationId?: string
  locationName: string
  serviceCategoryId?: string
  invoiceNumber?: number
  invoiceDate?: Date
  chapter?: string
  chapterCode?: ChapterCodeType
  header?: string
  code?: string
  invoiced: number
  formattedInvoiced: string
  cost: number
  formattedCost: string
  margin: number
  formattedMargin: string
}

export interface IGpPackageListTotal {
  invoiced: number
  formattedInvoiced: string
  cost: number
  formattedCost: string
  margin: number
  formattedMargin: string
}

export interface IGpPackageListResponse extends IPaginationFilteredResponse {
  invoices: IGpPackageListItem[]
  totals: IGpPackageListTotal
}
