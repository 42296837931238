import { ITimePeriodRequest } from '@lla-platform/core/core-data-access'
import { ChapterCodeType } from '../enums/chapter-code-type.enum'

export interface IPackageUnperformedRequest extends ITimePeriodRequest {
  locationId?: string
}

export interface IPackageUnperformedItem {
  invoiceId: string
  locationId: string
  locationName: string
  serviceCategoryId: string
  serviceCategoryName: string
  invoiceNumber: number
  invoiceDate: Date
  chapter: string
  chapterCode: ChapterCodeType
  header: string
  invoiced: number
  formattedInvoiced: string
  cost: number
  formattedCost: string
  margin: number
  formattedMargin: string
}

export interface IPackageUnperformedResponse {
  items: IPackageUnperformedItem[]
  mechanicalThreshold: number
  tireThreshold: number
}
