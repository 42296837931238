import { ITimePeriod } from '@lla-platform/core/core-data-access'

export interface ITrackerRequest {
  yearMonth?: string
  locationId: string
}

export interface ITrackerInfo {
  label: string
  value: number
  valueFormatted: string
  formula: string
}

export interface ITrackerItem {
  timePeriod: ITimePeriod
  items: ITrackerInfo[]
}

export interface ITrackerResponse {
  list: ITrackerItem[]
}
