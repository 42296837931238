import { IBaseUser, Permission } from '@lla-platform/users/users-data-access'
import { IAdminShopTiersItem } from '../../interfaces/admin-shop-tires.interface'
import { IAdminUserPermissionsResponse } from '../../interfaces/admin-user-permissions.interface'

export interface AdminStateModel {
  shopTiers: IAdminShopTiersItem[]
  allUsers: IBaseUser[]
  userPermissions: IAdminUserPermissionsResponse
  allPermissions: Permission[]
}
