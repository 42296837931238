import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FiltersState } from '@lla-platform/core/core-data-access'
import { AddNewBookmark, BookmarkType } from '@lla-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { finalize } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'lla-add-bookmark-dialog',
  templateUrl: './add-bookmark-dialog.component.html',
  styleUrls: ['./add-bookmark-dialog.component.scss']
})
export class AddBookmarkDialogComponent implements OnInit {
  form: UntypedFormGroup
  isLoading = false

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddBookmarkDialogComponent>,
    private store: Store,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { bookmarkType: BookmarkType }
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, [Validators.required]]
    })
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.isLoading = true
      this.store
        .dispatch(
          new AddNewBookmark({
            name: this.form.get('name')?.value,
            token: this.store.selectSnapshot(FiltersState.urlFilterParam),
            type: this.data.bookmarkType
          })
        )
        .pipe(
          untilDestroyed(this),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(() => {
          this.toastr.success('New Bookmark Added')
          this.dialogRef.close()
        })
    }
  }
}
