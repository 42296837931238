import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'lla-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage = 1
  @Input() totalPages?: number
  @Input() maxSize = 6

  @Output() pageChange = new EventEmitter<number>()
}
