import { PeriodType } from '../enums/period-type.enum'

export interface ITimePeriod {
  periodType: PeriodType
  period: string
  from: Date | string
  to: Date | string
}

export interface IStringTimePeriod {
  from?: string
  to?: string
}

export interface ITimePeriodRequest {
  periodType: PeriodType
  from?: Date | string
  to?: Date | string
}

export interface ITimePeriodWithWeekNumber {
  period: string
  weekNumber: number
  startOfWeek: Date
  endOfWeek: Date
}
