import { IUrlFilters } from '@lla-platform/core/core-data-access'
import { FullReceivableType } from '../enums/shop-receivable-type.enum'

export interface IReceivablesSummaryByShopRequest {
  receivablesType: FullReceivableType
  accountName?: string
  locationIds?: string[]
}

export interface IShopReceivableItem {
  atRiskAmount: number
  notRiskAmount: number
  total: number
}

export interface IShopReceivableDetails extends IUrlFilters {
  locationId: string
  locationName?: string
  accountName?: string
  over0_30?: number
  formattedOver0_30: string
  over30_60?: number
  formattedOver30_60: string
  over60_90?: number
  formattedOver60_90: string
  over90_Plus?: number
  formattedOver90_Plus: string
  total?: number
  formattedTotal: string
}

export interface IGrandTotal extends IUrlFilters {
  formattedOver0_30: string
  formattedOver30_60: string
  formattedOver60_90: string
  formattedOver90_Plus: string
  formattedTotal: string
}

export interface IShopReceivableResponse {
  locationReceivables: IShopReceivableDetails[]
  grandTotal: IGrandTotal
}
