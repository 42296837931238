import { ITimePeriod } from '@lla-platform/core/core-data-access'

export interface ICallReportItem {
  shopName: string
  shopNickname: string
  callsAmount: number
}

export interface ICallReportResponse {
  timePeriod: ITimePeriod
  shopCommunications: ICallReportItem[]
}
