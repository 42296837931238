import { Component, inject, OnInit } from '@angular/core'
import { SetFilters } from '@lla-platform/core/core-data-access'
import { IMenuRouteInfo } from '@lla-platform/core/core-feature'
import {
  BookmarkType,
  IUser,
  Permission,
  Role,
  UserAccountState
} from '@lla-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { ApplicationRoutes } from '../../../app-routes-names'
import { ProfilePaths } from '../../../features/profile/profile-route-names'
import { CustomersPaths } from '../../../features/user-panel/customers/customers-route-names'
import { ReceivablePaths } from '../../../features/user-panel/receivable/receivable-route-names'
import { ShopPerformancePaths } from '../../../features/user-panel/shop-performance/shop-performance-route-names'
import { CommunicationsPaths } from '../../../features/user-panel/communications/communications-route-names'
import { SamplesPaths } from '../../../features/user-panel/samples/samples-route-names'
import { TargetsPaths } from '../../../features/user-panel/targets/targets-route-names'
import { ReportsPaths } from '../../../features/user-panel/reports/reports-route-names'
import { environment } from '@lla-platform/core/core-util'
import { PaymentsPaths } from '../../../features/user-panel/payments/payments-route-names'
import { InventoriesPaths } from '../../../features/user-panel/inventories/inventories-route-names'
import { AdminPaths } from '../../../features/admin-panel/admin-route-names'
import { TiresPaths } from '../../../features/user-panel/tires/tires-route-names'
import { WeekToDateQueryParam } from './main-layout.constant'
import { WorkOrdersPaths } from '../../../features/user-panel/work-orders/work-orders-route-names'

@UntilDestroy()
@Component({
  selector: 'lla-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  allRoles = Role
  showLargeMenu = false

  userMainRoutes: IMenuRouteInfo[] = []

  userProfileRoutes: IMenuRouteInfo[] = [
    {
      label: 'My Profile',
      icon: 'user-circle',
      path: ProfilePaths.editProfile
    }
  ]

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(
      new SetFilters({
        listPagesPath: {
          [BookmarkType.Receivable]: ReceivablePaths.receivablesList,
          [BookmarkType.Invoice]: ShopPerformancePaths.invoicesList,
          [BookmarkType.Customer]: CustomersPaths.list,
          [BookmarkType.Communication]: CommunicationsPaths.list,
          [BookmarkType.Payment]: PaymentsPaths.paymentsList,
          [BookmarkType.Inventory]: InventoriesPaths.list,
          [BookmarkType.Tire]: TiresPaths.list,
          [BookmarkType.InvoiceGrossProfit]: ShopPerformancePaths.grossProfitInvoicesList,
          [BookmarkType.InvoicePackageGrossProfit]: ShopPerformancePaths.gpPackageInvoicesList,
          [BookmarkType.LostTiresReport]: TiresPaths.lostTiresList,
          [BookmarkType.WorkOrders]: WorkOrdersPaths.list
        }
      })
    )

    this.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      const userRole = user?.role
      const userPermissions = user?.permissions ?? []
      this.userMainRoutes = [
        {
          label: 'Dashboard',
          icon: 'dashboard',
          path: ApplicationRoutes.dashboard,
          type: 'main'
        },
        {
          label: 'Receivables',
          icon: 'receive-money',
          type: 'service',
          hidden: !userPermissions.includes(Permission.Receivables),
          subMenus: [
            {
              label: 'Locations',
              icon: 'redo',
              path: ReceivablePaths.shopsSummary,
              hidden: !userPermissions.includes(Permission.Receivables)
            },
            {
              label: 'Accounts',
              icon: 'redo',
              path: ReceivablePaths.accountsSummary,
              hidden: !userPermissions.includes(Permission.Receivables)
            },
            {
              label: 'AR Sales',
              icon: 'redo',
              path: ReceivablePaths.arInvoicesSummary,
              hidden: !userPermissions.includes(Permission.Receivables)
            },
            {
              label: 'AR Payments',
              icon: 'redo',
              path: ReceivablePaths.arPaymentsSummary,
              hidden: !userPermissions.includes(Permission.Receivables)
            }
          ]
        },
        {
          label: 'Invoices',
          icon: 'building',
          type: 'service',
          hidden: !userPermissions.includes(Permission.Invoices),
          subMenus: [
            {
              label: 'By Customer Type',
              icon: 'redo',
              path: ShopPerformancePaths.invoicesCustomerType,
              hidden: !userPermissions.includes(Permission.Invoices)
            },
            {
              label: 'By Gross Profit',
              icon: 'redo',
              path: ShopPerformancePaths.invoicesGrossProfit,
              hidden: !userPermissions.includes(Permission.Invoices)
            },
            {
              label: 'By Deferred Type',
              icon: 'redo',
              path: ShopPerformancePaths.invoicesDeferredType,
              hidden: environment.production || !userPermissions.includes(Permission.Invoices)
            },
            {
              label: 'Week To Date',
              icon: 'redo',
              path: ShopPerformancePaths.invoicesList,
              queryParams: WeekToDateQueryParam,
              hidden: !userPermissions.includes(Permission.Invoices)
            }
          ]
        },
        {
          label: 'Packages GP',
          icon: 'inventory',
          type: 'service',
          hidden: !userPermissions.includes(Permission.PackageGp),
          subMenus: [
            {
              label: 'By Location',
              icon: 'redo',
              path: ShopPerformancePaths.gpPackageLocation,
              hidden: !userPermissions.includes(Permission.PackageGp)
            },
            {
              label: 'By Category',
              icon: 'redo',
              path: ShopPerformancePaths.gpPackageCategory,
              hidden: !userPermissions.includes(Permission.PackageGp)
            },
            {
              label: 'By Performance',
              icon: 'redo',
              path: ShopPerformancePaths.performancePackageGp,
              hidden: !userPermissions.includes(Permission.PackageGp)
            }
          ]
        },
        {
          label: 'Contacts',
          icon: 'users',
          type: 'service',
          hidden: !userPermissions.includes(Permission.Contacts),
          subMenus: [
            {
              label: 'Contacts List',
              icon: 'redo',
              path: CustomersPaths.list,
              hidden: !userPermissions.includes(Permission.Contacts)
            },
            {
              label: 'Phone/Email Overview',
              icon: 'redo',
              path: CustomersPaths.communicationType,
              hidden: !userPermissions.includes(Permission.Contacts)
            }
          ]
        },
        {
          label: 'Reports',
          icon: 'chart',
          type: 'service',
          hidden:
            !userPermissions.includes(Permission.Reports) &&
            !userPermissions.includes(Permission.LostTiresReport),
          subMenus: [
            {
              label: 'Lost Tires Report',
              icon: 'redo',
              path: TiresPaths.lostTiresList,
              hidden: !userPermissions.includes(Permission.LostTiresReport)
            },
            {
              label: 'Sales Report',
              icon: 'redo',
              path: ReportsPaths.sales,
              hidden: !userPermissions.includes(Permission.Reports)
            }
          ]
        },
        {
          label: 'Work Orders',
          icon: 'ballot',
          type: 'service',
          path: ApplicationRoutes.workOrders,
          hidden: !userPermissions.includes(Permission.WorkOrders)
        },
        // TODO: Show me
        // {
        //   label: 'Tires',
        //   icon: 'wheel',
        //   type: 'service',
        //   hidden: !userPermissions.includes(Permission.Inventories),
        //   subMenus: [
        //     {
        //       label: 'Summary',
        //       icon: 'redo',
        //       path: TiresPaths.summary,
        //       hidden: !userPermissions.includes(Permission.Inventories)
        //     },
        //     {
        //       label: 'Offers',
        //       icon: 'redo',
        //       path: TiresPaths.offers,
        //       hidden: !userPermissions.includes(Permission.Inventories)
        //     }
        //   ]
        // },
        // TODO: Remove me
        {
          label: 'Tires',
          icon: 'wheel',
          type: 'service',
          path: TiresPaths.summary,
          hidden: !userPermissions.includes(Permission.Inventories)
        },
        {
          label: 'Trackers',
          icon: 'tree',
          type: 'service',
          path: ApplicationRoutes.tracker,
          hidden: !userPermissions.includes(Permission.Tracker)
        },
        {
          label: 'Reviews',
          icon: 'check-list',
          type: 'service',
          path: ApplicationRoutes.reviews,
          hidden: !userPermissions.includes(Permission.Reviews)
        },
        {
          label: 'Weekly Target',
          icon: 'target',
          type: 'service',
          subMenus: [
            {
              label: 'Objectives',
              icon: 'redo',
              path: TargetsPaths.objectives
            },
            {
              label: 'Ranking by Locations',
              icon: 'redo',
              path: TargetsPaths.locations
            },
            {
              label: 'Ranking by Regions',
              icon: 'redo',
              path: TargetsPaths.regions
            }
          ]
        },
        {
          label: 'Weekly Car Count',
          icon: 'calendar',
          type: 'service',
          path: ShopPerformancePaths.weeklyCarCount
        },
        {
          label: 'Lost Sales Tire Tracker',
          icon: 'link-break',
          type: 'service',
          path: TiresPaths.lostTire,
          hidden: !userPermissions.includes(Permission.LostSales)
        },
        {
          label: 'Who to Call?',
          icon: 'phone',
          type: 'service',
          path: ApplicationRoutes.whoToCall
        },
        {
          label: 'Promotions',
          icon: 'star-outline',
          type: 'service',
          path: ApplicationRoutes.promotions,
          hidden: !userPermissions.includes(Permission.Promotions)
        },
        {
          label: 'Comps (soon)',
          icon: 'grid',
          path: '',
          type: 'service',
          exact: true,
          hidden: environment.production
        },
        {
          label: 'Samples (Demo Only)',
          icon: 'chart',
          type: 'service',
          hidden: environment.production,
          subMenus: [
            {
              label: 'Sankey Chart',
              icon: 'redo',
              path: SamplesPaths.sankeyChart,
              hidden: environment.production
            },
            {
              label: 'Sales Chart',
              icon: 'redo',
              path: SamplesPaths.salesChart,
              hidden: environment.production
            },
            {
              label: 'Comp Chart',
              icon: 'redo',
              path: SamplesPaths.compChart,
              hidden: environment.production
            },
            {
              label: 'Map',
              icon: 'redo',
              path: SamplesPaths.map,
              hidden: environment.production
            },
            {
              label: 'Location Map',
              icon: 'redo',
              path: SamplesPaths.shopMap,
              hidden: environment.production
            },
            {
              label: 'Map Clustering',
              icon: 'redo',
              path: SamplesPaths.mapClustering,
              hidden: environment.production
            },
            {
              label: 'Map Markers',
              icon: 'redo',
              path: SamplesPaths.mapMarkers,
              hidden: environment.production
            }
          ]
        },
        {
          label: 'Admin',
          icon: 'user-circle',
          type: 'service',
          hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin),
          subMenus: [
            {
              label: 'Set Location Targets',
              icon: 'redo',
              path: AdminPaths.shopTires,
              hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin)
            },
            {
              label: 'Set Margins For Categories',
              icon: 'redo',
              path: AdminPaths.setMargins,
              hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin)
            },
            {
              label: 'Users',
              icon: 'redo',
              path: AdminPaths.userList,
              hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin)
            },
            {
              label: 'Promotions',
              icon: 'redo',
              path: AdminPaths.promotionsList,
              hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin)
            },
            {
              label: 'Surveys',
              icon: 'redo',
              path: AdminPaths.surveyList,
              hidden: userRole !== Role.admin && !userPermissions.includes(Permission.Admin)
            }
          ]
        }
      ]
    })
  }

  onShowLargeMenuChange(state: boolean) {
    this.showLargeMenu = state
    this.store.dispatch(
      new SetFilters({
        showLargeMenu: this.showLargeMenu
      })
    )
  }
}
