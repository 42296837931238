export enum BookmarkType {
  Receivable = 'Receivable',
  Customer = 'Customer',
  Invoice = 'Invoice',
  Communication = 'Communication',
  Payment = 'Payment',
  Inventory = 'Inventory',
  Tire = 'Tire',
  InvoiceGrossProfit = 'InvoiceGrossProfit',
  InvoicePackageGrossProfit = 'InvoicePackageGrossProfit',
  LostTiresReport = 'LostTiresReport',
  WorkOrders = 'WorkOrders'
}

export const BookmarkTypeToLabel: { [key: string]: string } = {
  Receivable: 'Receivables',
  Invoice: 'Invoices',
  Customer: 'Contacts',
  Communication: 'Calls',
  Payment: 'Payments',
  Inventory: 'Inventories',
  Tire: 'Tires',
  InvoiceGrossProfit: 'Gross Profit',
  InvoicePackageGrossProfit: 'Package Gross Profit',
  LostTiresReport: 'Lost Tires Report',
  WorkOrders: 'Work Orders'
}
