import { OrderType } from '../enums/order-type.enum'
import { IPaginationRequest } from '../interfaces/pagination-request.interface'

export function convertPaginationRequest(request: IPaginationRequest): {
  [param: string]: string | string[]
} {
  const limit = request.limit ?? 10
  const page = request.page ?? 1
  const query: any = {
    limit: limit.toString(),
    offset: ((page - 1) * limit).toString(),
    query: request.query ?? '',
    orderType: `${request.orderType ?? OrderType.DESC}`
  }

  if (request.orderField) {
    query.orderField = request.orderField
  }
  return query
}
