import { PromotionChannelType } from '../enums/promotion-channel-type.enum'

export interface IPromotionChannelItem {
  id: string
  name: string
  orderNo: number
  channel: PromotionChannelType
}

export interface IPromotionChannelResponse {
  items: IPromotionChannelItem[]
}
