import { Component, Input } from '@angular/core'

@Component({
  selector: 'lla-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() variant:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'error'
    | 'error-invert'
    | 'black'
    | 'black-invert'
    | 'ghost' = 'primary'
  @Input() size: 'large' | 'medium' | 'small' | 'smaller' = 'large'
  @Input() type: 'button' | 'menu' | 'reset' | 'submit' = 'button'
  @Input() disabled = false
  @Input() loading = false
  @Input() iconType: 'matIcon' | 'iconPath' | 'svgIcon' = 'matIcon'
  @Input() icon: string
  @Input() iconPosition: 'left' | 'right' = 'left'
  @Input() iconClass = ''
  @Input() iconFontsize = '16px'
  @Input() extraBtnClass = ''
}
