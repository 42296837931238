import { ILostTiresListResponse } from '../../interfaces/lost-tires-list.interface'
import { ILostTireResponse } from '../../interfaces/lost-tires.interface'
import { ITiresListResponse } from '../../interfaces/tires-list.interface'
import { ITiresResponse } from '../../interfaces/tires.interface'

export interface TiresStateModel {
  tires: ITiresResponse
  listResponse: ITiresListResponse
  lostTireInfo: ILostTireResponse
  lostTireslistResponse: ILostTiresListResponse
}
