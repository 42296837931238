import { ApplicationRoutes } from '../../../app-routes-names'

export const WorkOrdersRoutes = {
  summary: 'summary',
  list: 'list'
}

const { workOrders } = ApplicationRoutes

export const WorkOrdersPaths = {
  summary: `/${workOrders}/${WorkOrdersRoutes.summary}`,
  list: `/${workOrders}/${WorkOrdersRoutes.list}`
}
