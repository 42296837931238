export const environment = {
  production: false,
  name: 'dev',
  apiUrl: 'https://jarvis.cojita.com/api/v1',
  websiteUrl: 'https://jarvis.cojita.com',
  googlePlacesKey: 'AIzaSyBVuu27h1inWvon6YgA3byPYU1HCJRwCKo',
  googleAnalyticsToken: 'G-BDR5V65N22',
  pageSize: 20,
  jwtSecret: 'simple_jwt_secret'
}
