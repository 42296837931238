import { IPromotionChannelItem } from '../../interfaces/promotion-channel.interface'
import { IPromotionUsersItem } from '../../interfaces/promotion-users.interface'
import {
  IAdminPromotionItem,
  IPromotionItem,
  IShopPromotionsItem
} from '../../interfaces/promotions.interface'

export interface PromotionsStateModel {
  promotion: IPromotionItem
  allPromotions: IShopPromotionsItem[]
  adminAllPromotions: IAdminPromotionItem[]
  promotionChannels: IPromotionChannelItem[]
  promotionUsers: IPromotionUsersItem[]
}
