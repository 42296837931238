import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { ReceivableType } from '../enums/shop-receivable-type.enum'

export interface IReceivableDetailedList extends IAdvancedTable {
  id?: string
  accountNumber?: string
  accountName?: string
  posted?: string
  type?: string
  description?: string
  outstanding: number
  formattedOutstanding: string
  over0_30: number
  formattedOver0_30: string
  over30_60: number
  formattedOver30_60: string
  over60_90: number
  formattedOver60_90: string
  over90_Plus: number
  formattedOver90_Plus: string
  ratedType?: ReceivableType
  locationName: string
  isPayed?: boolean
}

export interface IReceivableDetailedTotal extends IAdvancedTable {
  outstanding: number
  formattedOutstanding: string
  over0_30: number
  formattedOver0_30: string
  over30_60: number
  formattedOver30_60: string
  over60_90: number
  formattedOver60_90: string
  over90_Plus: number
  formattedOver90_Plus: string
}

export interface IReceivableDetailedResponse extends IPaginationFilteredResponse {
  receivables: IReceivableDetailedList[]
  totals: IReceivableDetailedTotal
}

export interface IUpdateReceivableRequest {
  ratedType: ReceivableType
}
