import { IAgent } from '../../interfaces/agents.interface'
import { ICommunicationsTypeResponse } from '../../interfaces/communication-type.interface'
import { ICommunicationsListResponse } from '../../interfaces/communications-list.interface'
import {
  ICommunicationHistoryItem,
  ICommunicationResponse
} from '../../interfaces/communications.interface'
import { ICustomerGeoLocationResponse } from '../../interfaces/customers-geolocation.interface'
import { ICustomerInvoicesResponse } from '../../interfaces/customers-invoice.interface'
import { ICustomersListResponse } from '../../interfaces/customers-list.interface'
import { ITagItem } from '../../interfaces/customers-tag.interface'
import { ICustomerGetItem, ICustomersTypeResponse } from '../../interfaces/customers.interface'
import { IDeferredTypeResponse } from '../../interfaces/deferred.interface'

export interface CustomersStateModel {
  customers: ICustomersTypeResponse
  listResponse: ICustomersListResponse
  customer: ICustomerGetItem
  tags: ITagItem[]
  invoicesResponse: ICustomerInvoicesResponse
  communication: ICommunicationResponse
  communicationHistories: ICommunicationHistoryItem[]
  communicationsList: ICommunicationsListResponse
  agents: IAgent[]
  communicationsType: ICommunicationsTypeResponse
  customersInRadius: ICustomerGeoLocationResponse
  deferredTypes: IDeferredTypeResponse
}
