export interface IUserPreferencesRequest {
  preferences: string[]
}

export interface IPreferenceItem {
  preference: string
  preferenceName: string
}

export interface IUserPreferencesResponse {
  preferences: string[]
  availablePreferences: IPreferenceItem[]
}
